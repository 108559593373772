import React, { useState, useEffect } from 'react';

import AuthService from '../../services/auth/auth.service';
import UserService from '../../services/auth/user.service';
// import logOut from '../../services/auth/auth.service';

import './Board.css';

const BoardTuto = () => {
	const [showAdminBoard, setShowAdminBoard] = useState(false);
	const [currentUser, setCurrentUser] = useState(undefined);
	const [content, setContent] = useState('');

	// const msgToken = "Sans autorisation. Le Token d'Accès a expiré.";

	useEffect(() => {
		const user = AuthService.getCurrentUser();
		if (user) {
			setCurrentUser(user);
			setShowAdminBoard(user.roles.includes('ROLE_ADMIN'));
		}

		UserService.getAdminBoard().then(
			(response) => {
				setContent(response.data);
			},
			(error) => {
				const _content =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setContent(_content);
			},
		);
	}, []);

	return (
		<div className='container'>
			<header className='jumbotron jumbotron-top'>
				<h3>{content}</h3>
			</header>
			<div>
				{currentUser && showAdminBoard && (
					<div>
						<h1>Hi 👋 Voici le tutoriel d'envoi d'images</h1>
						<p>
							Le module d'upload d'images se trouve dans deux emplacements
							réservés aux admins : <br />- partie ajout de structure
							(https://www.siaellhc.fr/#/nouvelleStructure) <br />- partie
							modification de structure (https://www.siaellhc.fr/#/structures/5)
						</p>
						<p>Pour utiliser ce module :</p>
						<p>
							1) Choisir son fichier image sur son disque dur en cliquant sur
							parcourir,{' '}
						</p>
						<p>2) valider son choix,</p>{' '}
						<p>
							3) cliquer sur envoyer (attendre la confirmation d'envoi réussi
							sur Google cloud),
						</p>
						<p>
							4) repérer le nom du fichier envoyé dans la liste affichée juste
							en dessous,
						</p>
						<p>
							{' '}
							(Important) 5) cliquer sur le second lien affiché pour copier
							automatiquement le lien du fichier
						</p>
						<p>
							{' '}
							(Facultatif) 6) ou cliquer sur le premier lien pour télécharger le
							fichier
						</p>
						<p>7) (Important) coller le lien dans le champ "Lien du Logo".</p>
					</div>
				)}
			</div>
			<div>
				<a
					href='/#/admin'
					class='btn btn-secondary btn-lg active'
					role='button'
					aria-pressed='true'>
					Retourner au panneau d'administration
				</a>
			</div>
		</div>
	);
};

export default BoardTuto;
