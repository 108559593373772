export default function authHeader() {
	// renverra la valeur d'une clé du localStorage, ou null si la clé n'existe pas
	const user = JSON.parse(localStorage.getItem('user'));

	if (user && user.accessToken) {
		// "x-access-token" pour le serveur
		return { 'x-access-token': user.accessToken };
	} else {
		return {};
	}
}
