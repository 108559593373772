import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import CheckButton from 'react-validation/build/button';

import AuthService from '../../../../../services/auth/auth.service';
import CategoryDataService from '../../../../../services/CategoryService';
import {
	required,
	vTitle,
	vSubtitle,
	vQuestion,
	vDescription,
} from '../../../../../utils/validation/fieldsCategorie';

const AddCategory = () => {
	const form = useRef();
	const checkBtn = useRef();

	const initialCategoryState = {
		id: null,
		title: '',
		subtitle: '',
		question: '',
		description: '',
		published: false,
	};

	const [category, setCategory] = useState(initialCategoryState);
	const [submitted, setSubmitted] = useState(false);
	const [message, setMessage] = useState('');
	const [showAdminBoard, setShowAdminBoard] = useState(false);

	useEffect(() => {
		const user = AuthService.getCurrentUser();
		if (user) {
			setShowAdminBoard(user.roles.includes('ROLE_ADMIN'));
		}
	}, []);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setCategory({ ...category, [name]: value });
	};

	const newCategory = () => {
		setCategory(initialCategoryState);
		setSubmitted(false);
	};

	const saveCategory = (e) => {
		e.preventDefault();
		setMessage('');
		setSubmitted(false);
		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			const data = {
				title: category.title,
				subtitle: category.subtitle,
				question: category.question,
				description: category.description,
			};

			CategoryDataService.create(data)
				.then(
					(response) => {
						setMessage(response.data.message);
						setSubmitted(true);

						setCategory({
							id: response.data.id,
							title: response.data.title,
							subtitle: response.data.subtitle,
							question: response.data.question,
							description: response.data.description,
							published: response.data.published,
						});
						// console.log(response.data);
					},
					(error) => {
						const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();

						setMessage(resMessage);
						setSubmitted(false);
					},
				)
				.catch((e) => {
					console.log(e);
				});
		}
	};

	return (
		<div className='submit-form'>
			{showAdminBoard ? (
				showAdminBoard && (
					<Form onSubmit={saveCategory} ref={form}>
						{submitted ? (
							<div className='jumbotron jumbotron-top'>
								<h4>Votre publication a été envoyée avec succès !</h4>
								<button className='btn btn-success' onClick={newCategory}>
									Ajouter
								</button>
							</div>
						) : (
							<div>
								<div className='form-group'>
									<label className='add-tuto-top' htmlFor='title'>
										Titre <span className='unbold'>(ex: AI)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='title'
										name='title'
										required
										value={category.title}
										onChange={handleInputChange}
										validations={[required, vTitle]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='subtitle'>
										Sous-titre{' '}
										<span className='unbold'>
											(ex: Associations Intermédiaires)
										</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='subtitle'
										required
										value={category.subtitle}
										onChange={handleInputChange}
										name='subtitle'
										validations={[required, vSubtitle]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='question'>
										Question{' '}
										<span className='unbold'>
											(ex: Qu’est ce qu’une association intermédiaire ?)
										</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='question'
										required
										value={category.question}
										onChange={handleInputChange}
										name='question'
										validations={[required, vQuestion]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='description'>
										Description{' '}
										<span className='unbold'>
											(ex: Les AI mettent leurs salariés à disposition
											d’utilisateurs...)
										</span>
									</label>
									<Textarea
										className='form-control'
										id='description'
										required
										value={category.description}
										onChange={handleInputChange}
										name='description'
										rows={5}
										cols={8}
										validations={[required, vDescription]}
									/>
								</div>
								<button className='btn btn-success add-tuto-bottom'>
									Envoyer
								</button>
							</div>
						)}

						{message && (
							<div className='form-group'>
								<div
									className={
										submitted ? 'alert alert-success' : 'alert alert-danger'
									}
									role='alert'>
									{message}
								</div>
							</div>
						)}
						<CheckButton style={{ display: 'none' }} ref={checkBtn} />
					</Form>
				)
			) : (
				<div className='jumbotron jumbotron-top'>
					Vous ne disposez pas des droits pour atteindre cette page privée.
				</div>
			)}
		</div>
	);
};

export default AddCategory;
