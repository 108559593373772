import React from 'react';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './legalFramework.css';

const LegalFramework = () => {
	return (
		<div className='home end__legalFramework' id='cloud-container'>
			<div className='home__logo--header legal--bordure test-width'>
				<div className='juridique__head--bordure'></div>
				<div className='public-cible'>
					<strong>
						<span className='public-cible--title'>PUBLIC CIBLE</span>
					</strong>
					<br />
					<p className='public-cible--content'>
						Les demandeurs d'emploi de longue durée, les bénéficiaires de minima
						sociaux, les jeunes en grande difficulté, les séniors de plus de 50
						ans, les travailleurs reconnus handicapés.
					</p>
				</div>
				<div className='tableExample'>
					<Table>
						<caption className='title_iae_legal'>
							Le cadre juridique de l'I.A.E. :
						</caption>
						<Thead>
							<Tr>
								<Th className='thead__bcolor'>
									<strong>ASSOCIATIONS INTERMÉDIAIRES</strong>
								</Th>
								<Th className='thead__bcolor'>
									<strong>ATELIERS ET CHANTIERS D'INSERTION</strong>
								</Th>
								<Th className='thead__bcolor'>
									<strong>ENTREPRISES D'INSERTION</strong>
								</Th>
								<Th className='thead__bcolor'>
									<strong>ENTREPRISES DE TRAVAIL TEMPORAIRE D'INSERTION</strong>
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							<Tr>
								<Td className='td__bcolor'>
									Proposent des mises à disposition auprès de particuliers,
									d'associations, de collectivités ou d'entreprises pour la
									réalisation de travaux occassionnels
								</Td>
								<Td className='td__bcolor'>
									Développement des activités d'utilité sociale répondant à des
									besoins collectifs non couverts par ailleurs
								</Td>
								<Td className='td__bcolor'>
									Produisent des biens et des services destinés à être
									commercialisés sur un marché
								</Td>
								<Td className='td__bcolor'>
									Proposent des missions d'intérim auprès d'entreprises clientes
								</Td>
							</Tr>
							<Tr>
								<Td colspan='4' className='td-spancol thead__bcolor'>
									<strong>STATUT</strong>
								</Td>
							</Tr>
							<Tr>
								<Td className='td__bcolor'>Associations de loi 1901</Td>
								<Td className='td__bcolor'>
									Structures créées et portées par des organismes de droit privé
									à but non lucratif ou des structures publiques
								</Td>
								<Td className='td__bcolor'>
									Entreprises du secteur marchand (SA, SARL, association loi
									1901...)
								</Td>
								<Td className='td__bcolor tempo-margin-b'>
									Entreprises de travail temporaire
								</Td>
							</Tr>
							<Tr>
								<Td colspan='4' className='td-spancol thead__bcolor'>
									<strong>MISSIONS</strong>
								</Td>
							</Tr>
							<Tr>
								<Td className='td__bcolor'>
									Accueil, suivi et accompagnement des personnes en difficulté.
									Mise à disposition de personnes sans emploi auprès de
									particuliers, collectivités locales, associations et
									entreprises
								</Td>
								<Td className='td__bcolor'>
									Suivi accompagnement, encadrement technique et
									socio-professionnel et formation des personnes les plus
									éloignées de l'emploi
								</Td>
								<Td className='td__bcolor'>
									Insertion par l'offre d'une activité productive assortie de
									différentes prestations définies selon les besoins et la
									situation des personnes (réeentrainement au rythme de travail,
									formation, accompagnement social...)
								</Td>
								<Td className='td__bcolor'>
									Insertion professionnelle, suivi social et professionnel
									pendant et en dehors des missions
								</Td>
							</Tr>
							<Tr>
								<Td colspan='4' className='td-spancol thead__bcolor'>
									<strong>CONTRAT DE TRAVAIL</strong>
								</Td>
							</Tr>
							<Tr className='lastone'>
								<Td className='td__bcolor'>
									CDD, CDI à temps partiel ou CDDI. La durée totale de mise à
									disposition en entreprise ne peut excéder 480 heures dans les
									24 mois suivant la première mise à disposition
								</Td>
								<Td className='td__bcolor'>
									Salariés embauchés en CDDI de 24 mois maximum
								</Td>
								<Td className='td__bcolor'>CDDI de 24 mois maximum</Td>
								<Td className='td__bcolor'>
									Contrat de travail temporaire ou contrat de mission de 24 mois
									maximum
								</Td>
							</Tr>
						</Tbody>
					</Table>
				</div>
			</div>
		</div>
	);
};

export default LegalFramework;
