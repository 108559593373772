import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import StructureDataService from '../../../../services/StructureService.js';
import CategoryDataService from '../../../../services/CategoryService.js';
import formatPhoneNumber from '../../../../utils/FormatPhoneNumber.js';

import '../../HomePage/homePage.css';
import '../../../../logos/asso/logos.css';
import './structureAll.css';

const StructureETTI = (props) => {
	const [currentStructure, setCurrentStructure] = useState([]);
	const [currentCategory, setCurrentCategory] = useState([]);
	const [loading, setLoading] = useState(true);

	const handleLoad = () => {
		setLoading(false);
	};

	const getCategory = (id) => {
		CategoryDataService.get(id)
			.then((response) => {
				setCurrentCategory(response.data);
				// console.log(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const getStructure = (id) => {
		StructureDataService.get(id)
			.then((response) => {
				// console.log(response.data);
				setCurrentStructure(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		getStructure(props.match.params.id);
		getCategory(35);
	}, [props.match.params.id]);

	const groupId = [];

	const PreviousNextElement = () => {
		currentCategory.structures.map((structure) => groupId.push(structure.id));
		const len = groupId.length;
		const firstStructureId = groupId[0];
		const lastStructureId = groupId[len - 1];

		return (
			<div className='block'>
				{groupId ? (
					firstStructureId === currentStructure.id ? (
						<div key={currentStructure.id} className='bold block--1link'>
							<Link
								to={`/etti/${groupId[1]}`}
								title='Vers la structure suivante'
								className='link-next-prev'>
								Suivant
							</Link>
						</div>
					) : lastStructureId === currentStructure.id ? (
						<div key={currentStructure.id} className='bold block--1link'>
							<Link
								to={`/etti/${groupId[len - 2]}`}
								title='Vers la structure précédente'
								className='link-next-prev'>
								Précédent
							</Link>
						</div>
					) : (
						<div key={currentStructure.id} className='bold block--2links'>
							<Link
								to={`/etti/${
									groupId[groupId.indexOf(currentStructure.id) - 1]
								}`}
								title='Vers la structure précédente'
								className='link-next-prev'>
								Précédent
							</Link>
							<Link
								to={`/etti/${
									groupId[groupId.indexOf(currentStructure.id) + 1]
								}`}
								title='Vers la structure suivante'
								className='link-next-prev'>
								Suivant
							</Link>
						</div>
					)
				) : (
					''
				)}
			</div>
		);
	};

	return (
		<div>
			{currentCategory.structures ? (
				<div className='edit-form-initial bottom-100 top-115'>
					<div className='form-group top-25 prev-center'>
						<PreviousNextElement />
					</div>
					<p className='bold'>{currentStructure.title}</p>
					<p className='bold'>
						{currentStructure.description === 'Aucun'
							? ''
							: currentStructure.description}
					</p>
					{loading && (
						<div class='d-flex justify-content-center'>
							<div class='spinner-border text-primary' role='status'>
								<span class='sr-only'>Loading...</span>
							</div>
						</div>
					)}
					<img
						className={'logoStructure structure' + currentStructure.id}
						src={currentStructure.sample_logo_file}
						alt={'logo ' + currentStructure.title}
						onLoad={handleLoad}
					/>
					<p>
						<span className='bold'>Type de conventionnement :</span>{' '}
						{currentStructure.type_of_agreement}
					</p>
					<p>
						<span className='bold'>Statut juridique :</span>{' '}
						{currentStructure.legal_statut}
					</p>
					<p>
						<span className='bold'>Date de création :</span>{' '}
						{currentStructure.creation_date}
					</p>
					<p>
						<span className='bold'>Nom et prénom du responsable :</span>{' '}
						{currentStructure.supervisor_identity}
					</p>
					<p>
						<span className='bold'>Qualité :</span> {currentStructure.quality}
					</p>
					<p>
						<span className='bold'>Personne à contacter :</span>{' '}
						{currentStructure.contact_person}
					</p>
					<p>
						<span className='bold'>Adresse postale complète :</span>{' '}
						{currentStructure.full_address}
					</p>
					<p>
						<span className='bold'>Téléphone : </span>
						{currentStructure.phone === 'Aucun' ? (
							currentStructure.phone
						) : (
							<a
								href={`tel:${formatPhoneNumber(currentStructure.phone)}`}
								title={'Appeler le ' + currentStructure.phone}
								className='link-inclusion'>
								{currentStructure.phone}
							</a>
						)}
					</p>
					<p>
						<span className='bold'>Portable : </span>
						{currentStructure.mobile === 'Aucun' ? (
							currentStructure.mobile
						) : (
							<a
								href={`tel:${formatPhoneNumber(currentStructure.mobile)}`}
								title={'Appeler le ' + currentStructure.mobile}
								className='link-inclusion'>
								{currentStructure.mobile}
							</a>
						)}
					</p>
					{/* <p>
						<span className='bold'>Fax : </span>
						{currentStructure.fax === 'Aucun' ? (
							currentStructure.fax
						) : (
							<a
								href={`fax:${formatPhoneNumber(currentStructure.fax)}`}
								title={'Envoyer un fax au ' + currentStructure.fax}
								className='link-inclusion'>
								{currentStructure.fax}
							</a>
						)}
					</p> */}
					<p>
						<span className='bold'>Email :</span>{' '}
						<a
							href={`mailto:${currentStructure.email}`}
							class='btn btn-primary'>
							{currentStructure.email}
						</a>
					</p>
					<p>
						<span className='bold'>Site Internet :</span>{' '}
						{currentStructure.website === 'Aucun' ? (
							currentStructure.website
						) : (
							<a
								href={currentStructure.website}
								title={
									'Cliquez pour ouvrir une page vers ' +
									currentStructure.website
								}
								// rel="opener"
								rel='noreferrer'
								target='_blank'
								className='link-inclusion'>
								{currentStructure.website}
							</a>
						)}
					</p>
					<p>
						<span className='bold'>Domaine professionnel :</span>{' '}
						{currentStructure.professionnal_domain}
					</p>
					<p>
						<span className='bold'>Commune d'implantation :</span>{' '}
						{currentStructure.established_city}
					</p>
					<p>
						<span className='bold'>Les autres sites :</span>{' '}
						{currentStructure.other_city}
					</p>
					<p>
						<span className='bold'>Clients :</span> {currentStructure.clients}
					</p>
					<p>
						<span className='bold'>Appartenance à un groupe en réseau :</span>{' '}
						{currentStructure.network_membership}
					</p>
					<p>
						<span className='bold'>
							Description de l'activité économique de la structure :
						</span>{' '}
						{currentStructure.description_iae}
					</p>
					<p>
						<span className='bold'>
							Débouchés professionnels possibles pour les salariés en insertion
							:
						</span>{' '}
						{currentStructure.career_opportunities}
					</p>
				</div>
			) : (
				<div>
					<br />
					<div class='d-flex justify-content-center'>
						<div class='spinner-border text-primary' role='status'>
							<span class='sr-only'>En chargement...</span>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default StructureETTI;
