import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import AuthService from '../../services/auth/auth.service';
import UserService from '../../services/auth/user.service';

import './Board.css';

const BoardAdmin = () => {
	const [showAdminBoard, setShowAdminBoard] = useState(false);
	const [currentUser, setCurrentUser] = useState(undefined);
	const [content, setContent] = useState('');

	const history = useHistory();
	const msgToken = "Sans autorisation. Le Token d'Accès a expiré.";

	useEffect(() => {
		const user = AuthService.getCurrentUser();
		if (user) {
			setCurrentUser(user);
			setShowAdminBoard(user.roles.includes('ROLE_ADMIN'));
		}

		UserService.getAdminBoard().then(
			(response) => {
				setContent(response.data);
			},
			(error) => {
				const _content =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setContent(_content);
			},
		);
	}, []);

	/**
	 * update message for deconnection
	 */
	const sendMsgLogout = () => {
		let tmp = content;
		tmp += ' (Déconnexion dans 5 sec...)';
		setContent(tmp);
	};

	/**
	 * Send deconnection message and logout after 5sec
	 */
	const logOut = () => {
		sendMsgLogout();
		setTimeout(() => {
			AuthService.logout();
		}, '5000');
	};

	/**
	 * redirect to login after 2sec
	 */
	const pushToLogin = () => {
		setTimeout(() => {
			history.push('/login');
		}, '2000');
	};

	const logoutAndLogin = () => {
		logOut();
		pushToLogin();
	};

	return (
		<div className='container div-jumbo'>
			<header className='jumbotron jumbotron-top'>
				<h3>{content === msgToken ? logoutAndLogin() : content}</h3>
			</header>
			<div>
				{currentUser && showAdminBoard && (
					<div className='dropright marg-bottom-40'>
						<button
							className='btn btn-primary dropdown-toggle'
							type='button'
							id='dropdownMenuButton'
							data-toggle='dropdown'
							aria-haspopup='true'
							aria-expanded='false'>
							Catégories
						</button>
						<div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
							<a className='dropdown-item' href='/#/categories'>
								Voir
							</a>
							<a className='dropdown-item' href='/#/nouvelleCategorie'>
								Ajouter
							</a>
						</div>
					</div>
				)}
				{showAdminBoard && (
					<div className='dropright marg-bottom-40'>
						<button
							className='btn btn-success dropdown-toggle'
							type='button'
							id='dropdownMenuButton'
							data-toggle='dropdown'
							aria-haspopup='true'
							aria-expanded='false'>
							Structures
						</button>
						<div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
							<a className='dropdown-item' href='/#/structures'>
								Voir
							</a>
							<a className='dropdown-item' href='/#/nouvelleStructure'>
								Ajouter
							</a>
						</div>
					</div>
				)}
				{showAdminBoard && (
					<div className='dropright'>
						<button
							className='btn btn-info dropdown-toggle'
							type='button'
							id='dropdownMenuButton'
							data-toggle='dropdown'
							aria-haspopup='true'
							aria-expanded='false'>
							Tutoriels
						</button>
						<div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
							<a className='dropdown-item' href='/#/tutoUploadImg'>
								Voir le tutoriel d'envoi d'images
							</a>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default BoardAdmin;
