import React from 'react';

import LogosHeader from '../Logos/Header/LogosHeader';
import LogosFooter from '../Logos/Footer/LogosFooter';

import '../HomePage/homePage.css';
import '../Thanks/thanks.css';

const Thanks = () => {
	return (
		<div className='home' id='cloud-container'>
			<LogosHeader />
			<div className='home__content'>
				<div className='home__header'></div>
				<div>
					<h2 className='thanks__description'>
						Ce guide a été réalisé par les PLIEs de Lens-Liévin et
						d’Hénin-Carvin en partenariat avec :
					</h2>
					<ul className='thanks__partenariat'>
						<li className='partenariat'>La DDETS,</li>
						<li className='partenariat'>La Région Hauts-de-France,</li>
						<li className='partenariat'>Le Département du Pas-de-Calais,</li>
						<li className='partenariat'>
							La Communauté d’Agglomération de Lens-Liévin,
						</li>
						<li className='partenariat'>
							La Communauté d’Agglomération d’Hénin-Carvin,
						</li>
						<li className='partenariat'>Les S.I.A.E.</li>
						<li className='partenariat'>France Travail,</li>
						<p className='signature'>
							L'application et son déploiement ont été réalisés par le
							développeur <strong>Jérôme Demuynck</strong> durant son stage de{' '}
							<strong>Développeur Web et Mobile</strong> (Formation{' '}
							<em>Pop School</em>) pour le <strong>PLIE de Lens-Liévin.</strong>
						</p>
					</ul>
				</div>
			</div>

			<LogosFooter />
		</div>
	);
};

export default Thanks;
