import http from '../http-common';

// L'objet params aura tous les champs: title, page, size
const getAll = (params) => {
	return http.get('/structures', { params });
};

const get = (id) => {
	return http.get(`/structures/${id}`);
};

const create = (data) => {
	return http.post('/structures', data);
};

const update = (id, data) => {
	console.log(id);
	console.log(data);
	return http.put(`/structures/${id}`, data);
};

const remove = (id) => {
	console.log(id);
	return http.delete(`/structures/${id}`);
};

const findByTitle = (title) => {
	return http.get(`/structures?title=${title}`);
};

const exportedStructureObject = {
	getAll,
	get,
	create,
	update,
	remove,
	findByTitle,
};

export default exportedStructureObject;
