// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// /**
//  * Component that will scroll the window up on every navigation
//  *
//  * @returns null
//  */
// const ScrollToTop = () => {
// 	const { pathname } = useLocation();

// 	useEffect(() => {
// 		window.scrollTo(0, 0);
// 	}, [pathname]);

// 	return null;
// };

// export default ScrollToTop;

import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ children, location: { pathname } }) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [pathname]);

	return children || null;
};

export default withRouter(ScrollToTop);
