import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

import App from './App.js';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './utils/ScrollToTop.js';

// https://reactrouter.com/web/api/HashRouter
ReactDOM.render(
	<HashRouter basename='/'>
		<ScrollToTop>
			<App />
		</ScrollToTop>
	</HashRouter>,
	document.getElementById('root'),
);

serviceWorker.unregister();
