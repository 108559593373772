import React from 'react';
import { Link } from 'react-router-dom';

import LogosHeader from '../Logos/Header/LogosHeader';
import LogosFooter from '../Logos/Footer/LogosFooter';

import './homePage.css';

const HomePage = () => {
	return (
		<div className='home' id='cloud-container'>
			<div className='home__left'></div>
			<div className='home__right'></div>
			<LogosHeader />
			<div className='home__content'>
				<div className='home__header'></div>
				<div>
					<h1 className='home__title'>
						INSERTION PAR <br />
						L’ACTIVITÉ ÉCONOMIQUE
					</h1>
					<h2 className='home__description'>
						Répertoire des <br />
						Structures d’Insertion par l’Activité <br />
						Économique (SIAE)
						<br /> du bassin de Lens-Liévin / Hénin-Carvin
					</h2>

					<div className='organizations'>
						<div className='organization-ai'>
							<Link to={'/ai'} title='Vers les Associations Intermédiaires'>
								AI
							</Link>
						</div>
						<div className='organization-aci'>
							<Link
								to={'/aci'}
								title='Vers les Ateliers et Chantiers d’Insertion'>
								ACI
							</Link>
						</div>
						<div className='organization-ei'>
							<Link to={'/ei'} title='Vers les Entreprises d’Insertion'>
								EI
							</Link>
						</div>
						<div className='organization-etti'>
							<Link
								to={'/etti'}
								title='Vers les Entreprises de Travail Temporaire d’Insertion'>
								ETTI
							</Link>
						</div>
					</div>
				</div>
			</div>
			<LogosFooter />
		</div>
	);
};

export default HomePage;
