import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ImageLoader from 'react-loading-image';

import './Hello.css';

const Hello = () => {
	const [value, setValue] = useState(null);

	const onChange = (e) => {
		setValue(e.target.value);
	};

	return (
		<div class='container'>
			<div class='row'>
				<div class='col-sm-8 mt-3'>
					<p className='message-top'>
						Clique pour cheminer vers "/oops" qui n'est pas une route
						enregistrée:
					</p>
					<Link className='link-inclusion' to='/oops'>
						Allons-y
					</Link>

					{/* Test loading image */}
					<h1>Copy an image URL here</h1>
					<input onChange={onChange} />
					<p>value: {value}</p>
					<div>
						{value && (
							<ImageLoader
								src={value}
								loading={() => <div>Chargement...</div>}
								error={() => <div>Erreur</div>}
							/>
						)}
					</div>
					{/* End Test */}
				</div>
			</div>
			<hr />
		</div>
	);
};

export default Hello;
