import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import CategoryDataService from '../../../services/CategoryService.js';

import './assoIntermed.css';

const EntreprisesTravailTempo = () => {
	const [currentCategory, setCurrentCategory] = useState([]);

	const getCategory = (id) => {
		CategoryDataService.get(id)
			.then((response) => {
				setCurrentCategory(response.data);
				// console.log(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		// getCategory(4); // DEV
		getCategory(35); // PROD
	}, []);

	return (
		<div id='top-tag'>
			{currentCategory && currentCategory?.structures ? (
				<div className='edit-form-initial'>
					<h1>{currentCategory.title}</h1>
					<h2>{currentCategory.subtitle}</h2>
					<h3>{currentCategory.question}</h3>
					<p>{currentCategory.description}</p>
					<div className='form-group top-25'>
						<ul className='loop__structures2'>
							Les <span className='bold'>{currentCategory.title}</span> du
							Bassin :
							{currentCategory.structures.map((item) => {
								return (
									<li
										key={item.id}
										className={
											'indent bold li-hover ' +
											(item.id === 275 ? 'first-li' : '')
										}>
										<Link
											to={'/etti/' + item.id}
											title={`Vers la page de la structure ${item.title}`}>
											{item.title}
										</Link>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			) : (
				<div className='d-flex justify-content-center'>
					<div className='spinner-border text-primary' role='status'>
						<span className='sr-only'>Loading...</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default EntreprisesTravailTempo;
