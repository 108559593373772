import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';

import AuthService from '../../services/auth/auth.service';

const required = (value) => {
	if (!value) {
		return (
			<div className='alert alert-danger' role='alert'>
				Ce champs est obligatoire.
			</div>
		);
	}
};

const Login = (props) => {
	const form = useRef();
	const checkBtn = useRef();
	const history = useHistory();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');

	const onChangeUsername = (event) => {
		const username = event.target.value;
		setUsername(username);
	};

	const onChangePassword = (event) => {
		const password = event.target.value;
		setPassword(password);
	};

	const handleLogin = (event) => {
		event.preventDefault();

		setMessage('');
		setLoading(true);

		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			AuthService.login(username, password).then(
				() => {
					history.push('/profile');
					window.location.reload();
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setLoading(false);
					setMessage(resMessage);
				},
			);
		} else {
			setLoading(false);
		}
	};

	return (
		<div className='col-md-12 jumbotron-top'>
			<div className='card card-container'>
				<img
					src='//ssl.gstatic.com/accounts/ui/avatar_2x.png'
					alt='profile-img'
					className='profile-img-card'
				/>

				<Form onSubmit={handleLogin} ref={form}>
					<div className='form-group'>
						<label htmlFor='username'>Nom de l'utilisateur</label>
						<Input
							type='text'
							autoComplete='username'
							className='form-control'
							name='username'
							value={username}
							onChange={onChangeUsername}
							validations={[required]}
						/>
					</div>

					<div className='form-group'>
						<label htmlFor='password'>Mot de passe</label>
						<Input
							type='password'
							autoComplete='current-password'
							className='form-control'
							name='password'
							value={password}
							onChange={onChangePassword}
							validations={[required]}
						/>
					</div>

					<div className='form-group'>
						<button className='btn btn-primary btn-block' disabled={loading}>
							{loading && (
								<span className='spinner-border spinner-border-sm'></span>
							)}
							<span>Se connecter</span>
						</button>
					</div>

					{message && (
						<div className='form-group'>
							<div className='alert alert-danger' role='alert'>
								{message}
							</div>
						</div>
					)}
					<CheckButton style={{ display: 'none' }} ref={checkBtn} />
				</Form>
			</div>
		</div>
	);
};

export default Login;
