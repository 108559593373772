import React, { useRef } from 'react';
import useClippy from 'use-clippy';

import CopyIcon from './link.png';

export default function CopyElement(props) {
	/* eslint-disable no-unused-vars */
	const [clipboard, setClipboard] = useClippy();
	/* eslint-enable no-unused-vars */

	const myContainer = useRef(null);

	// useEffect(() => {
	//   console.log('myContainer..', myContainer.current);
	// });

	return (
		<div className='container-inline'>
			<button
				type='button'
				className='icon-bg'
				data-toggle='tooltip'
				data-placement='right'
				title='Cliquez pour copier le lien'>
				<img
					className='icon-copy'
					src={CopyIcon}
					alt='Icone Copier'
					onClick={() => {
						setClipboard(props.value);
						setTimeout(function () {
							myContainer.current.textContent =
								'Vous avez copié le lien en mémoire.';
						}, 100);
						setTimeout(function () {
							myContainer.current.textContent = '';
						}, 8000);
					}}
				/>
			</button>
			<div className='message-display' ref={myContainer}></div>
		</div>
	);
}
