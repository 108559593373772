import React, {useState} from 'react';

import logoRFMinisteres from '../../../../logos/Republique_Francaise_RVB.png';
import logoHDF from '../../../../logos/logo_HDF.svg';
import logoPdCDepartement from '../../../../logos/logo_PdC_62_depart.png';
import logoFranceTravail from '../../../../logos/logo_france-travail.jpg';
import logoCommuAgglLL from '../../../../logos/logo_commu_agglo_lens_lievin.png';
import logoCommuAggloHC from '../../../../logos/logo_commu_agglo_henin_carvin.png';

import '../../HomePage/homePage.css';

const LogosFooter = () => {
	const [loading, setLoading] = useState(true);

	const handleLoad = () => {
		setLoading(false);
	};

	return (
		<div className='home__logo--footer'>
			{loading && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-border text-primary' role='status'>
						<span class='sr-only'>Loading...</span>
					</div>
				</div>
			)}
			<img
				className='logo rf-ministeres'
				src={logoRFMinisteres}
				alt='Logo de ministères de la République Française'
				onLoad={handleLoad}
			/>
			<img
				className='logo france-travail'
				src={logoFranceTravail}
				alt='Logo de France Travail'
				onLoad={handleLoad}
			/>
			<img
				className='logo haut-de-france'
				src={logoHDF}
				alt='Logo des Hauts-de-France'
				onLoad={handleLoad}
			/>
			<img
				className='logo pas-de-calais_depart'
				src={logoPdCDepartement}
				alt='Logo du Département du Pas-de-Calais'
				onLoad={handleLoad}
			/>
			<img
				className='logo commu-agglo-ll'
				src={logoCommuAgglLL}
				alt="Logo de la Communauté d'Agglomération de Lens-Liévin"
				onLoad={handleLoad}
			/>
			<img
				className='logo commu-agglo-hc'
				src={logoCommuAggloHC}
				alt="Logo de la Communauté d'Agglomération d'Henin-Carvin"
				onLoad={handleLoad}
			/>
		</div>
	);
};

export default LogosFooter;
