import http from '../http-common'; // import Axios

const upload = (file, onUploadProgress) => {
	let formData = new FormData();

	formData.append('file', file);

	return http.post('/upload', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		// Afficher les "progress events" => à fin de monitoring/test uniquement car gourmand
		onUploadProgress,
	});
};

const getFiles = () => {
	return http.get('/files');
};

const FileUploadService = {
	upload,
	getFiles,
};

export default FileUploadService;
