import { isEmail } from 'validator';

import { validData } from './fieldsCategorie';

const validDataMax = (value, words, maxLen) => {
	if (value.length > maxLen) {
		return (
			<div className='alert alert-danger' role='alert'>
				{`Le champ '${words}' doit posséder au maximum ${maxLen} caractères.`}
			</div>
		);
	}
};

// Pour les champs obligatoires
export const vLastName = (value) => {
	let maxLen = 25;
	let words = 'Nom';
	return validData(value, words, maxLen);
};

export const vFirstName = (value) => {
	let maxLen = 45;
	let words = 'Prénom';
	return validData(value, words, maxLen);
};

export const vSubject = (value) => {
	let maxLen = 50;
	let words = 'Objet';
	return validDataMax(value, words, maxLen);
};

export const vMessage = (value) => {
	let maxLen = 250;
	let words = 'Message';
	return validDataMax(value, words, maxLen);
};

export const vTitle = (value) => {
	let maxLen = 50;
	let words = 'Titre';
	return validData(value, words, maxLen);
};

export const validEmail = (value) => {
	let words = 'Email';
	if (!isEmail(value)) {
		return (
			<div className='alert alert-danger' role='alert'>
				{`Le champ '${words}' n'est pas valide !`}
			</div>
		);
	}
};

// Champs non-obligatoires
export const vDescription = (value) => {
	let maxLen = 150;
	let words = 'Description';
	return validDataMax(value, words, maxLen);
};

export const vTypeAgree = (value) => {
	let maxLen = 80;
	let words = 'Type de conventionnement';
	return validDataMax(value, words, maxLen);
};

export const vLegalStatut = (value) => {
	let maxLen = 80;
	let words = 'Statut juridique';
	return validDataMax(value, words, maxLen);
};

export const vDate = (value) => {
	let maxLen = 150;
	let words = 'Date de création';
	return validDataMax(value, words, maxLen);
};

export const vSupervisor = (value) => {
	let maxLen = 30;
	let words = 'Nom et prénom du responsable';
	return validDataMax(value, words, maxLen);
};

export const vQuality = (value) => {
	let maxLen = 30;
	let words = 'Qualité';
	return validDataMax(value, words, maxLen);
};

export const vContact = (value) => {
	let maxLen = 150;
	let words = 'Personne à contacter';
	return validDataMax(value, words, maxLen);
};

export const vAddress = (value) => {
	let maxLen = 150;
	let words = 'Adresse postale complète';
	return validDataMax(value, words, maxLen);
};

export const vTel = (value) => {
	let maxLen = 150;
	let words = 'Téléphone';
	return validDataMax(value, words, maxLen);
};

export const vPort = (value) => {
	let maxLen = 150;
	let words = 'Portable';
	return validDataMax(value, words, maxLen);
};

export const vFax = (value) => {
	let maxLen = 30;
	let words = 'Fax';
	return validDataMax(value, words, maxLen);
};

export const vWebsite = (value) => {
	let maxLen = 100;
	let words = 'Site Internet';
	return validDataMax(value, words, maxLen);
};

export const vProDomain = (value) => {
	let maxLen = 250;
	let words = 'Domaine professionnel';
	return validDataMax(value, words, maxLen);
};

export const vCity = (value) => {
	let maxLen = 150;
	let words = "Commune d'implantation";
	return validDataMax(value, words, maxLen);
};

export const vClients = (value) => {
	let maxLen = 150;
	let words = 'Clients';
	return validDataMax(value, words, maxLen);
};

export const vNetworkMember = (value) => {
	let maxLen = 30;
	let words = 'Appartenance à un groupe en réseau';
	return validDataMax(value, words, maxLen);
};
