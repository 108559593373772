import React, { useState, useEffect, useRef } from 'react';

import UploadService from '../../services/FileUploadService';
import CopyElement from './CopyElement';

import './fileUpload.css';

const UploadFiles = () => {
	const [selectedFiles, setSelectedFiles] = useState(undefined);
	const [progressInfos, setProgressInfos] = useState({ val: [] });
	const [message, setMessage] = useState([]);
	const [fileInfos, setFileInfos] = useState([]);
	const progressInfosRef = useRef(null);

	// fonction pour récupérer les fichiers selectionnés
	// de l'élément <input type="file" />
	const selectFiles = (event) => {
		setSelectedFiles(event.target.files);
		setProgressInfos({ val: [] });
	};

	const upload = (idx, file) => {
		let _progressInfos = [...progressInfosRef.current.val];
		return UploadService.upload(file, (event) => {
			// Le progrès sera calculé sur la base d'event.loaded et d'event.total
			// et la valeur de pourcentage est assignée à l'objet progressInfos grâce à l'index.
			_progressInfos[idx].percentage = Math.round(
				(100 * event.loaded) / event.total,
			);
			setProgressInfos({ val: _progressInfos });
		})
			.then(() => {
				setMessage((prevMessage) => [
					...prevMessage,
					'Ce fichier a été envoyé avec succès: ' + file.name,
				]);
			})
			.catch(() => {
				_progressInfos[idx].percentage = 0;
				setProgressInfos({ val: _progressInfos });

				setMessage((prevMessage) => [
					...prevMessage,
					'Ne peut pas envoyer ce fichier: ' + file.name,
				]);
			});
	};
	/*
    Pour chaque fichier: on invoque la fonction upload() qui apppelera
    la méthode UploadService.upload() avec un paramètre d'index (i),
    et renverra une Promesse
  */
	const uploadFiles = () => {
		try {
			const files = Array.from(selectedFiles);
			let _progressInfos = files.map((file) => ({
				percentage: 0,
				fileName: file.name,
			}));

			progressInfosRef.current = {
				val: _progressInfos,
			};

			const uploadPromises = files.map((file, i) => upload(i, file));

			Promise.all(uploadPromises)
				.then(() => {
					setTimeout(() => {
						UploadService.getFiles();
					}, 250);
				})
				.then((files) => {
					setFileInfos(files.data);
				})
				.catch((error) => {
					console.log(
						`Erreur lors de la récupération des fichiers : ${error} `,
					);
				});

			setMessage([]);
		} catch (error) {
			console.log(`Erreur lors de l'envoi des fichiers: ${error}`);
		}
	};

	useEffect(() => {
		UploadService.getFiles().then((response) => {
			setFileInfos(response.data);
		});
	}, []);

	return (
		/*     
        Dans ce code, on utilise Bootstrap Progress Bar:
             .progress est un wrapper
             .progress-bar intérieur pour indiquer la progression
             .progress-bar a besoin de l'attribut style pour paramétrer la largeur par pourcentage
             .progress-bar a également besoin de role et d'attributs aria pour le rendre accessible
              le label de la barre de progression est le texte à l'intérieur
     */
		/* 
        Pour afficher une Liste des fichiers envoyés, on itère à partir du tableau fileInfos en utilisant la fonction map().
        Sur chaque élément fichier, on utilise file.url en tant qu'attribut href et file.name pour afficher le texte.
     */
		<div>
			{progressInfos &&
				progressInfos.val.length > 0 &&
				progressInfos.val.map((progressInfo, index) => (
					<div className='mb-2' key={index}>
						<span>{progressInfo.fileName}</span>
						<div className='progress'>
							<div
								className='progress-bar progress-bar-info'
								role='progressbar'
								aria-valuenow={progressInfo.percentage}
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ width: progressInfo.percentage + '%' }}>
								{progressInfo.percentage}%
							</div>
						</div>
					</div>
				))}
			<div className='row my-3'>
				<div className='col-8'>
					<label className='btn btn-default p-0'>
						<input
							id='gallery-photo-add'
							type='file'
							accept='image/*'
							multiple
							onChange={selectFiles}
						/>
					</label>
				</div>
				<div className='col-4'>
					<button
						className='btn btn-success btn-sm'
						disabled={!selectedFiles}
						onClick={uploadFiles}>
						Envoyer
					</button>
				</div>
				<br />
				<br />
				<div className='gallery'></div>
			</div>

			{message.length > 0 && (
				<div className='alert alert-secondary' role='alert'>
					<ul>
						{message.map((item, i) => {
							return <li key={i}>{item}</li>;
						})}
					</ul>
				</div>
			)}
			<div className='card list-files'>
				<div className='card-header'>Liste de fichiers</div>
				<ul className='list-group list-group-flush'>
					{fileInfos &&
						fileInfos.map((file, index) => (
							<li className='list-group-item' key={index}>
								<a
									href={file.url}
									className='link-inclusion'
									title={"cliquez pour télécharger l'image " + file.name}>
									{file.name}
								</a>
								{'   '}
								<CopyElement
									value={
										'https://storage.googleapis.com/samsara-siae-ll-hc-images/' +
										file.name
									}
								/>
							</li>
						))}
				</ul>
			</div>
		</div>
	);
};

export default UploadFiles;
