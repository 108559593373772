import React from 'react';
import { useHistory } from 'react-router-dom';

import AuthService from '../../services/auth/auth.service';

const Profile = () => {
	const currentUser = AuthService.getCurrentUser();
	const history = useHistory();

	const LogoutFromProfile = () => {
		const baseUrlPushing = history.push('/');
		return <h3>{baseUrlPushing}</h3>;
	};

	return currentUser ? (
		<div className='container top-profil'>
			<header className='jumbotron'>
				<h3>
					Profil <strong>{currentUser.username}</strong>
				</h3>
			</header>
			<p>
				<strong>Token:</strong> {currentUser.accessToken.substring(0, 20)} ...{' '}
				{currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
			</p>
			<p>
				<strong>Id:</strong> {currentUser.id}
			</p>
			<p>
				<strong>E-mail:</strong> {currentUser.email}
			</p>
			<strong>Autorités:</strong>
			<ul>
				{currentUser.roles &&
					currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
			</ul>
		</div>
	) : (
		<div className='container'>{<LogoutFromProfile />}</div>
	);
};

export default Profile;
