import React from 'react';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import '../Iae/iae.css';

import ArrowRight from '../../../logos/arrow_right.jpg';
import ArrowDownUpRight from '../../../logos/arrow_up_right.jpg';

const Iae = () => {
	return (
		<div className='home' id='cloud-container'>
			<div className='home__logo--header iae--bordure'>
				<div className='iae__head--bordure'></div>
				<div className='text-padding'>
					<h3 className='iae__title padd'>
						Qu’est-ce que l’Insertion par l’Activité Economique : IAE ?
					</h3>
					<div className='iae__content'>
						<p className='iae_first_para'>
							L’Insertion par l’Activité Économique (IAE) a pour objet de
							permettre à des personnes sans emploi, rencontrant des difficultés
							sociales et professionnelles particulières de bénéficier de
							contrats de travail en vue de faciliter leur insertion
							professionnelle. Elle met en œuvre des modalités spécifiques
							d’accueil et d’accompagnement « article L5132-1 du code du travail
							»
						</p>
						<p>
							L’I.A.E. constitue une étape du parcours vers le monde classique
							du travail. Le passage par une Structure d’Insertion par
							l’Activité Économique (SIAE) permet de retrouver des habitudes
							professionnelles, de la confiance en soi, mais également de se
							former à un métier, d’acquérir un savoir faire et de résoudre les
							problèmes de logement, de santé etc...qui constituent autant de
							freins au parcours d’insertion. Pour cela un encadrement
							socio-professionnel et technique est mis en œuvre tout au long du
							parcours.
						</p>
						<div className='tableExample'>
							<Table className='table-iae'>
								<Thead>
									<Tr>
										<Th className='td-box'>
											Personne en difficulté, durablement exclue de l’emploi
										</Th>
										<Th className='td-box'>
											Accès direct difficile
											<img
												className='logo arrow-right'
												src={ArrowRight}
												alt='Fleche Droite'
												title='Fleche Droite'
											/>
										</Th>
										<th className='td-box'>Marché de l‘emploi</th>
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td></Td>
										<Td className='td-box'>
											Parcours d’Insertion par l’Activité Économique
										</Td>
										<Td className='td-box'>
											<img
												className='logo arrow-up-right'
												src={ArrowDownUpRight}
												alt='Fleche Haut Droit'
												title='Fleche Haut Droit'
											/>
										</Td>
									</Tr>
								</Tbody>
							</Table>
						</div>
						<h4 className='iae__assos'>
							Les personnes concernées par l’I.A.E :
						</h4>
						<p>
							L’Insertion par l’Activité Économique s’adresse à des personnes
							sans emploi durablement exclues du marché du travail qui
							rencontrent des difficultés sociales et/ou professionnelles. Les
							personnes admises à s’inscrire dans un parcours d’Insertion par
							l’Activité Economique vont alors enclencher une dynamique de
							réinsertion.
						</p>
						<p>
							L’orientation vers les structures de l’I.A.E. repose sur un{' '}
							<strong>agrément préalable à l’embauche</strong> (
							<em>Pass I.A.E.</em>{' '}
							<strong>
								<a
									href='https://emplois.inclusion.beta.gouv.fr/'
									target='_blank'
									rel='noreferrer'
									title='Vers la plateforme inclusion'>
									via la plateforme inclusion
								</a>
							</strong>
							) délivré par France Travail. L’agrément, valable pendant une
							durée de 24 mois, va ainsi permettre à la personne d’être en
							mesure d’intégrer une structure IAE. De nombreux acteurs locaux de
							l’insertion professionnelle sont prescripteurs de cet agrément :
							France Travail et ses partenaires cotraitants : Missions Locales,
							Cap Emploi et les intervenants sociaux désignés par le Préfet :
							PLIE, CHRS,...
						</p>
						<ul className='ul-colors'>
							L’Insertion par l’Activité Économique regroupe{' '}
							<span className='iae__assos'>
								plusieurs types de conventionnements :
							</span>
							<li className='li-color'>
								<a
									href='/#/ai'
									title='Voir la page des Associations Intermédiaires'>
									<div className='structure__spanGroup'>
										<span className='li__cat li-ai'></span>
										<span className='li__cat--word'>
											Les Associations Intermédiaires
										</span>
									</div>
								</a>
							</li>
							<li className='li-color'>
								<a
									href='/#/aci'
									title='Voir la page des Ateliers et Chantiers d’Insertion'>
									<div className='structure__spanGroup'>
										<span className='li__cat li-aci'></span>
										<span className='li__cat--word'>
											Les Ateliers Chantiers d’Insertion
										</span>
									</div>
								</a>
							</li>
							<li className='li-color'>
								<a
									href='/#/ei'
									title='Voir la page des Entreprises d’Insertion'>
									<div className='structure__spanGroup'>
										<span className='li__cat li-ei'></span>
										<span className='li__cat--word'>
											Les Entreprises d’Insertion
										</span>
									</div>
								</a>
							</li>
							<li className='li-color'>
								<a
									href='/#/etti'
									title='Voir la page des Entreprises de Travail Temporaire d’Insertion'>
									<div className='structure__spanGroup'>
										<span className='li__cat li-etti'></span>
										<span className='li__cat--word'>
											Les Entreprises de Travail Temporaire d’Insertion
										</span>
									</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Iae;
