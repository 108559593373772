import React, {
	useState,
	useEffect,
	useMemo,
	useRef,
	useCallback,
} from 'react';
import { useTable, useSortBy } from 'react-table';
import Pagination from '@material-ui/lab/Pagination';

import AuthService from '../../../../../services/auth/auth.service';
import StructureDataService from '../../../../../services/StructureService';

import './StructuresList.css';

const StructuresList = (props) => {
	const [structures, setStructures] = useState([]);
	const [searchTitle, setSearchTitle] = useState('');
	const [showAdminBoard, setShowAdminBoard] = useState(false);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const [pageSize, setPageSize] = useState(4);

	const pageSizes = [4, 8, 12];

	const structuresRef = useRef();
	structuresRef.current = structures;

	const onChangeSearchTitle = (e) => {
		const searchTitle = e.target.value;
		setSearchTitle(searchTitle);
	};

	const getRequestParams = (searchTitle, page, pageSize) => {
		let params = {};

		if (searchTitle) {
			params['title'] = searchTitle;
		}

		if (page) {
			params['page'] = page - 1;
		}

		if (pageSize) {
			params['size'] = pageSize;
		}

		return params;
	};

	const retrieveStructures = () => {
		const params = getRequestParams(searchTitle, page, pageSize);

		StructureDataService.getAll(params)
			.then((response) => {
				const { structures, totalPages } = response.data;

				setStructures(structures);
				setCount(totalPages);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(retrieveStructures, [page, pageSize, searchTitle]);

	useEffect(() => {
		const user = AuthService.getCurrentUser();
		if (user) {
			setShowAdminBoard(user.roles.includes('ROLE_ADMIN'));
		}
	}, []);

	const handlePageChange = (event, value) => {
		setPage(value);
	};

	const handlePageSizeChange = (event) => {
		setPageSize(event.target.value);
		setPage(1);
	};

	const openStructure = useCallback(
		(rowIndex) => {
			const id = structuresRef.current[rowIndex].id;
			props.history.push('/structures/' + id);
		},
		[props.history],
	);

	const deleteStructure = useCallback(
		(rowIndex) => {
			const id = structuresRef.current[rowIndex].id;

			StructureDataService.remove(id)
				.then((response) => {
					props.history.push('/structures');
					let newStructures = [...structuresRef.current];
					newStructures.splice(rowIndex, 1);
					setStructures(newStructures);
				})
				.catch((e) => {
					console.log(e);
				});
		},
		[props.history],
	);

	const columns = useMemo(
		() => [
			{
				Header: 'Titre',
				accessor: 'title',
			},
			{
				Header: 'Logo',
				accessor: 'sample_logo_file',
			},
			{
				Header: 'Description',
				accessor: 'description',
			},
			{
				Header: 'Type de conventionnement',
				accessor: 'type_of_agreement',
			},
			{
				Header: 'Statut juridique',
				accessor: 'legal_statut',
			},
			{
				Header: 'Date de création',
				accessor: 'creation_date',
			},
			{
				Header: 'Nom et prénom du responsable',
				accessor: 'supervisor_identity',
			},
			{
				Header: 'Qualité',
				accessor: 'quality',
			},
			{
				Header: 'Personne à contacter',
				accessor: 'contact_person',
			},
			{
				Header: 'Adresse postale complète',
				accessor: 'full_address',
			},
			{
				Header: 'Téléphone',
				accessor: 'phone',
			},
			{
				Header: 'Portable',
				accessor: 'mobile',
			},
			// {
			// 	Header: 'Fax',
			// 	accessor: 'fax',
			// },
			{
				Header: 'Email',
				accessor: 'email',
			},
			{
				Header: 'Site Internet',
				accessor: 'website',
			},
			{
				Header: 'Domaine professionnel',
				accessor: 'professionnal_domain',
			},
			{
				Header: "Commune d'implantation",
				accessor: 'established_city',
			},
			{
				Header: 'Les autres sites',
				accessor: 'other_city',
			},
			{
				Header: 'Clients',
				accessor: 'clients',
			},
			{
				Header: 'Appartenance à un groupe en réseau',
				accessor: 'network_membership',
			},
			{
				Header: "Description de l'activité économique de la structure",
				accessor: 'description_iae',
			},
			{
				Header:
					'Débouchés professionnels possibles pour les salariés en question',
				accessor: 'career_opportunities',
			},
			{
				Header: 'Status',
				accessor: 'published',
				Cell: (props) => {
					return props.value ? 'Publié' : 'En attente de publication';
				},
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				Cell: (props) => {
					const rowIdx = props.row.id;
					return (
						<div className='icon-group'>
							<div className='icon-edit'>
								<span onClick={() => openStructure(rowIdx)}>
									<i
										alt='Éditer'
										title='Éditer'
										className='far fa-edit action fa-2x'></i>
								</span>
							</div>
							<div className='icon-edit'>
								<span
									onClick={() => {
										const confirmBox = window.confirm(
											'Confirmez-vous la suppression de cette donnée ?',
										);
										if (confirmBox === true) {
											deleteStructure(rowIdx);
										}
									}}>
									<i
										alt='Supprimer'
										title='Supprimer'
										className='fas fa-trash action fa-2x ml-2'></i>
								</span>
							</div>
						</div>
					);
				},
			},
		],
		[openStructure, deleteStructure],
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable(
			{
				columns,
				data: structures,
			},
			useSortBy, // trie les colonnes de la table
		);

	/* Mobile Dev
    U should have listener to like window.addEventListener("deviceorientation", handleOrientation, true);
    and u should rotate whole table or override body, header all the react-table part
    i think easiest way to rotate whole page 90deg when orientation change..
  */

	return (
		<div>
			{showAdminBoard ? (
				showAdminBoard && (
					<div className='list row'>
						<div className='col-md-8'>
							<div className='input-group mb-3'>
								<input
									type='text'
									className='form-control'
									placeholder='Recherche par titre'
									value={searchTitle}
									onChange={onChangeSearchTitle}
								/>
								<div className='input-group-append'>
									<button
										className='btn btn-outline-secondary'
										type='button'
										onClick={retrieveStructures}>
										Rechercher
									</button>
								</div>
							</div>
						</div>
						<div className='col-md-6'>
							<a href='/#/nouvelleStructure'>
								<button
									type='button'
									class='btn btn-success'
									title='Ajouter une Structure'>
									Ajouter
								</button>
							</a>
						</div>
						<div className='col-md-12 list-table'>
							<div className='mt-3'>
								{"Nombre d'élements par Page: "}
								<select onChange={handlePageSizeChange} value={pageSize}>
									{pageSizes.map((size) => (
										<option key={size} value={size}>
											{size}
										</option>
									))}
								</select>

								<Pagination
									className='my-3'
									count={count}
									page={page}
									siblingCount={1}
									boundaryCount={1}
									variant='outlined'
									shape='rounded'
									onChange={handlePageChange}
								/>
							</div>
							<table
								className='table table-striped table-bordered'
								{...getTableProps()}>
								<thead>
									{headerGroups.map((headerGroup) => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map((column) => (
												<th
													{...column.getHeaderProps(
														column.getSortByToggleProps(),
													)}>
													{column.render('Header')}
													<span>
														{column.isSorted
															? column.isSortedDesc
																? '  🔽'
																: ' 🔼'
															: ''}
													</span>
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()}>
									{rows.map((row, i) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {
													return (
														<td {...cell.getCellProps()}>
															{cell.render('Cell')}
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				)
			) : (
				<div className='jumbotron jumbotron-top'>
					Vous ne disposez pas des droits pour atteindre cette page privée.
				</div>
			)}
		</div>
	);
};

export default StructuresList;
