const formatPhoneNumber = (phoneNumberString) => {
	// \d = 	[0 - 9]; = contenant 0-9
	// \D = 	[^0-9] = ne contenant pas de chiffre
	const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	const match = cleaned.match(/^(1|0)?(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		const AREA_CODE_FR = '+33';
		const intlCode = match[0] ? AREA_CODE_FR : '';
		return [intlCode, match[0][1], match[2][2], match[3], match[4]].join('');
	}
	return null;
};

export default formatPhoneNumber;
