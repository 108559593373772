import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import CheckButton from 'react-validation/build/button';

import CategoryDataService from '../../../../../services/CategoryService';
import AuthService from '../../../../../services/auth/auth.service';
import {
	required,
	vTitle,
	vSubtitle,
	vQuestion,
	vDescription,
} from '../../../../../utils/validation/fieldsCategorie';

import '../../../Assos/assoIntermed.css';

const Category = (props) => {
	const initialCategoryState = {
		id: null,
		title: '',
		subtitle: '',
		question: '',
		description: '',
		published: false,
	};
	const [currentCategory, setCurrentCategory] = useState(initialCategoryState);
	const [message, setMessage] = useState('');
	const [showAdminBoard, setShowAdminBoard] = useState(false);
	/* eslint-disable no-unused-vars */
	const [submitted, setSubmitted] = useState(false);

	const form = useRef();
	const checkBtn = useRef();

	const getCategory = (id) => {
		CategoryDataService.get(id)
			.then((response) => {
				setCurrentCategory(response.data);
				console.log(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		const user = AuthService.getCurrentUser();
		if (user) {
			setShowAdminBoard(user.roles.includes('ROLE_ADMIN'));
		}
		getCategory(props.match.params.id);
	}, [props.match.params.id]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setCurrentCategory({ ...currentCategory, [name]: value });
	};

	const updatePublished = (status, e) => {
		e.preventDefault();
		setMessage('');
		setSubmitted(false);
		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			const data = {
				id: currentCategory.id,
				title: currentCategory.title,
				subtitle: currentCategory.subtitle,
				question: currentCategory.question,
				description: currentCategory.description,
				published: status,
			};

			CategoryDataService.update(currentCategory.id, data)
				.then((response) => {
					setSubmitted(true);
					setCurrentCategory({ ...currentCategory, published: status });
					console.log(response.data);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	const updateCategory = (e) => {
		e.preventDefault();
		setMessage('');
		setSubmitted(false);
		form.current.validateAll();

		console.log('updateCategory currentCategory.id = ', currentCategory.id);
		console.log('updateCategory currentCategory = ', currentCategory);

		if (checkBtn.current.context._errors.length === 0) {
			CategoryDataService.update(currentCategory.id, currentCategory)
				.then((response) => {
					setSubmitted(true);
					console.log(response.data);
					setMessage('La catégorie a été correctement mise à jour !');
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	const deleteCategory = () => {
		console.log('deleteCategory currentCategory.id = ', currentCategory.id);

		CategoryDataService.remove(currentCategory.id)
			.then((response) => {
				console.log(response.data);
				props.history.push('/categories');
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<div>
			{showAdminBoard ? (
				showAdminBoard && (
					<div className='top-25'>
						{currentCategory ? (
							<div id='top-tag' className='edit-form-initial'>
								<h4>Catégorie</h4>
								<Form ref={form}>
									<div className='form-group'>
										<label htmlFor='title'>Titre</label>
										<Input
											type='text'
											className='form-control'
											id='title'
											name='title'
											value={currentCategory.title}
											onChange={handleInputChange}
											validations={[required, vTitle]}
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='subtitle'>Sous-titre</label>
										<Input
											type='text'
											className='form-control'
											id='subtitle'
											name='subtitle'
											value={currentCategory.subtitle}
											onChange={handleInputChange}
											validations={[required, vSubtitle]}
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='subtitle'>Question</label>
										<Input
											type='text'
											className='form-control'
											id='question'
											name='question'
											value={currentCategory.question}
											onChange={handleInputChange}
											validations={[required, vQuestion]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='description'>Description</label>
										<Textarea
											className='form-control'
											id='description'
											value={currentCategory.description}
											onChange={handleInputChange}
											name='description'
											rows={5}
											cols={8}
											validations={[required, vDescription]}
										/>
									</div>
									<div className='form-group'>
										<label>
											<strong>Statut:</strong>
										</label>
										{currentCategory.published ? 'Publié' : 'En attente'}
									</div>
									<div className='form-group top-25'>
										<ul className='loop__structures'>
											Les <span className='bold'>{currentCategory.title}</span>{' '}
											du Bassin :
											{currentCategory.structures
												? currentCategory.structures.map((item) => {
														return (
															<li
																key={item.id}
																className='indent bold link-inclusion'>
																<Link
																	to={'/structures/' + item.id}
																	title={`Pour modifier la structure ${item.title}`}>
																	{item.title}
																</Link>
															</li>
														);
												  })
												: 'Loading...'}
										</ul>
									</div>
									<CheckButton style={{ display: 'none' }} ref={checkBtn} />
								</Form>
								<div className='add-tuto-bottom top-45'>
									{currentCategory.published ? (
										<button
											className='badge badge-primary mr-3'
											onClick={() => updatePublished(false)}>
											Dépublier
										</button>
									) : (
										<button
											className='badge badge-primary mr-3'
											onClick={() => updatePublished(true)}>
											Publier
										</button>
									)}

									<button
										className='badge badge-danger mr-3'
										onClick={() => {
											const confirmBox = window.confirm(
												'Confirmez-vous la suppression de cette catégorie ?',
											);
											if (confirmBox === true) {
												deleteCategory();
											}
										}}>
										Supprimer
									</button>

									<button
										type='submit'
										className='badge badge-success mr-3'
										onClick={updateCategory}>
										Valider
									</button>
									<p>{message}</p>
								</div>
							</div>
						) : (
							<div>
								<br />
								<p>Veuillez cliquer sur une catégorie...</p>
							</div>
						)}
					</div>
				)
			) : (
				<div className='jumbotron jumbotron-top'>
					Vous ne disposez pas des droits pour atteindre cette page privée.
				</div>
			)}
		</div>
	);
};

export default Category;
