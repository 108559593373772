import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import AuthService from '../../../../../services/auth/auth.service';
import CategoryDataService from '../../../../../services/CategoryService';

import './CategoriesList.css';
import '../../../Assos/assoIntermed.css';

const CategoriesList = () => {
	const [categories, setCategories] = useState([]);
	const [currentCategory, setCurrentCategory] = useState(null);
	const [searchTitle, setSearchTitle] = useState('');
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [showAdminBoard, setShowAdminBoard] = useState(false);

	const onChangeSearchTitle = (e) => {
		const searchTitle = e.target.value;
		setSearchTitle(searchTitle);
	};

	const getRequestParams = (searchTitle) => {
		let params = {};
		if (searchTitle) {
			params['title'] = searchTitle;
		}
		return params;
	};

	const retrieveCategories = () => {
		const params = getRequestParams(searchTitle);

		CategoryDataService.getAll(params)
			.then((response) => {
				setCategories(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(retrieveCategories, [searchTitle]);

	useEffect(() => {
		const user = AuthService.getCurrentUser();
		if (user) {
			setShowAdminBoard(user.roles.includes('ROLE_ADMIN'));
		}
	}, []);

	const setActiveCategory = (category, index) => {
		setCurrentCategory(category);
		setCurrentIndex(index);
	};

	const findByTitle = () => {
		CategoryDataService.findByTitle(searchTitle)
			.then((response) => {
				setCategories(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<div>
			{showAdminBoard ? (
				<div className='list row'>
					<div className='col-md-6'>
						<div className='input-group mb-3'>
							<input
								type='text'
								className='form-control'
								placeholder='Recherche par titre'
								value={searchTitle}
								onChange={onChangeSearchTitle}
							/>
							<div className='input-group-append'>
								<button
									className='btn btn-outline-secondary'
									type='button'
									onClick={findByTitle}>
									Rechercher
								</button>
							</div>
						</div>
					</div>
					<div className='col-md-6'>
						{' '}
						<a href='/#/nouvelleCategorie'>
							<button type='button' class='btn btn-success'>
								Ajouter
							</button>
						</a>
					</div>
					<div className='col-md-3'>
						<h4 className='h4__categories'>Liste des Catégories</h4>

						<ul className='list-group'>
							{categories &&
								categories.map((category, index) => (
									<li
										className={
											'list-group-item ' +
											(index === currentIndex ? 'active' : '')
										}
										onClick={() => setActiveCategory(category, index)}
										key={index}>
										{category.title}
									</li>
								))}
						</ul>
					</div>
					<div className='col-md-9'>
						{currentCategory ? (
							<div>
								<h4 className='h4__categories'>Catégorie</h4>
								<div>
									<label>
										<strong>Titre:</strong>
									</label>{' '}
									{currentCategory.title}
								</div>
								<div>
									<label>
										<strong>Sous-titre:</strong>
									</label>{' '}
									{currentCategory.subtitle}
								</div>
								<div>
									<label>
										<strong>Question:</strong>
									</label>{' '}
									{currentCategory.question}
								</div>
								<div>
									<label>
										<strong>Description:</strong>
									</label>{' '}
									{currentCategory.description}
								</div>
								<div>
									<label>
										<strong>Statut:</strong>
									</label>{' '}
									{currentCategory.published
										? 'Publié'
										: 'En attente de publication'}
								</div>
								<div className='top-25'>
									<ul className='loop__structures'>
										Les <span className='bold'>{currentCategory.title}</span> du
										Bassin :
										{currentCategory.structures
											? currentCategory.structures.map((item) => {
													return (
														<li key={item.id} className='indent bold li-hover'>
															<Link
																to={'/structures/' + item.id}
																title={`Pour modifier la structure ${item.title}`}>
																{item.title}
															</Link>
														</li>
													);
											  })
											: 'Loading...'}
									</ul>
								</div>

								<Link
									to={'/categories/' + currentCategory.id}
									className='badge badge-warning mx-auto mt-2 add-tuto-bottom add-tuto-bottom-top'>
									Modifier
								</Link>
							</div>
						) : (
							<div>
								<br />
								<p>Veuillez cliquer sur une catégorie...</p>
							</div>
						)}
					</div>
				</div>
			) : (
				<div className='jumbotron jumbotron-top'>
					Vous ne disposez pas des droits pour atteindre cette page privée.
				</div>
			)}
		</div>
	);
};

export default CategoriesList;
