import React from 'react';
import './Footer.css';

const Footer = () => {
	return (
		<div className='footer-wrapper'>
			<a
				className='footer--link'
				href='/#/mentions'
				title='Consulter les mentions légales'>
				Mentions légales
			</a>
		</div>
	);
};

export default Footer;
