import React from 'react';
import './LegalNotices.css';

const LegalNotices = () => {
	return (
		<main>
			<div className='box-legal'>
				<h1 className='box-legal-title'>mentions légales</h1>
				<p className='box-legal-subtitle'>En vigueur au 01/03/2023</p>
				<p>
					Conformément aux dispositions des Articles 6-III et 19 de la Loi
					n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
					numérique, dite L.C.E.N., il est porté à la connaissance des
					utilisateurs du site{' '}
					<a
						target='_blank'
						rel='noreferrer'
						href='https://www.siaellhc.fr'
						title="Vers la page d'accueil du Répertoire SIAE">
						Répertoire SIAE
					</a>{' '}
					les présentes mentions légales.
				</p>
				<p>
					La connexion et la navigation sur le site{' '}
					<a
						target='_blank'
						rel='noreferrer'
						href='https://www.siaellhc.fr'
						title="Vers la page d'accueil du Répertoire SIAE">
						www.siaellhc.fr
					</a>{' '}
					par l’utilisateur implique l'acceptation intégrale et sans réserve des
					présentes mentions légales. Ces dernières sont accessibles sur le site
					à la rubrique « Mentions légales ».
				</p>
				<p>
					Pour toute suggestion, information, ou réaction concernant ce site,
					n'hésitez pas à{' '}
					<a
						target='_blank'
						rel='noreferrer'
						href='/#/contact'
						title='Vers le formulaire de contact'>
						nous contacter.
					</a>
				</p>
				<article>
					<h2 className='box-legal-article-title'>
						INFORMATIONS RELATIVES À L'ÉDITEUR
					</h2>
					<p>
						Le site Répertoire SIAE de Lens-Liévin et d'Hénin-Carvin est porté
						par le PLIE de Lens-Liévin Hénin-Carvin.
					</p>
					<p>
						<strong>PLIE LENS LIEVIN / HENIN CARVIN</strong>
						<br />
						91 avenue Jean Jaurès
						<br />
						62 800 Liévin
						<br />
						Tel : 03 21 74 80 40
						<br />
						Email :{' '}
						<a
							href='mailto:plielenslievin@plie-llhc.fr'
							title='Envoyer un e-mail au PLIE LENS LIEVIN / HENIN CARVIN'>
							plielenslievin@plie-llhc.fr
						</a>
						<br />
						Site Web :{' '}
						<a
							target='_blank'
							rel='noreferrer'
							href='https://lens-henin.minedinfos.fr/acteur/12/14-plie-lens-lievin.htm'
							title='Vers le site lens-henin.minedinfos.fr'>
							lens-henin.minedinfos.fr
						</a>
					</p>
					<p>
						Le Répertoire SIAE est un site qui permet de rendre facilement
						accessible l'information sur l'offre de SIAE identifiée sur le
						territoire de Lens-Liévin-Hénin-Carvin en matière de réponses liées
						à l'insertion sociale et professionnelle. Il s’adresse
						principalement aux professionnels de l’emploi, aux élus locaux, aux
						partenaires institutionnels ou encore à toutes les personnes (jeunes
						ou adultes) recherchant une information.
					</p>
					<h3 className='box-legal-h3'>Directeur de Publication</h3>
					<p>Rémi PENNEQUIN, Directeur Général</p>
					<h3 className='box-legal-h3'>Webmestre</h3>
					<p>Philippe LEFEBVRE, Chargé de Projet</p>
					<h3 className='box-legal-h3'>
						Hébergeur et Service de Base de données
					</h3>
					<p>
						OVH
						<br />2 rue Kellermann
						<br />
						59100 Roubaix - France
						<br />
						Tél : 09 72 10 10 07
						<br />
						Email :{' '}
						<a href='mailto:cil@ovh.net' title='Envoyer un e-mail à OVH'>
							cil@ovh.net
						</a>
					</p>
					<h3 className='box-legal-h3'>Conception et développement</h3>
					<p>
						Jérôme Demuynck
						<br />
						28 domaine chanteclair
						<br />
						62440 Harnes
						<br />
						Tel: 06 22 04 12 33
						<br />
						Email :{' '}
						<a
							href='mailto:samsaraonaya@gmail.com'
							title='Envoyer un e-mail au concepteur et développeur Jérôme Demuynck'>
							samsaraonaya@gmail.com
						</a>
						<br />
						Site Web :{' '}
						<a
							target='_blank'
							rel='noreferrer'
							href='https://samsara.live'
							title='Vers le site Web du concepteur et développeur Jérôme Demuynck'>
							samsara.live
						</a>
						<br />
					</p>
					<h3 className='box-legal-h3'>Accès au site</h3>
					<p>
						Le site est accessible par tout endroit, 7j/7, 24h/24, sauf cas de
						force majeure, interruption programmée ou non, et pouvant découler
						d’une nécessité de maintenance. En cas de modification, interruption
						ou suspension des services le site{' '}
						<a
							target='_blank'
							rel='noreferrer'
							href='https://lens-henin.minedinfos.fr/acteur/12/14-plie-lens-lievin.htm'
							title='Vers le site lens-henin.minedinfos'>
							lens-henin.minedinfos
						</a>{' '}
						ne saurait être tenu responsable.
					</p>
				</article>
				<article>
					<h2 className='box-legal-article-title'>DROITS D'AUTEUR</h2>
					<h3 className='box-legal-h3'>
						PROPRIÉTÉ INTELLECTUELLE ET DROIT DE REPRODUCTION
					</h3>
					<p>
						Toute représentation et/ou exploitation partielle ou totale de ce
						site est donc prohibée sans l’accord préalable écrit du PLIE de
						Lens-Liévin Hénin-Carvin.
					</p>
					<p>
						Toute reproduction partielle ou totale de ce site est prohibée sans
						l’accord préalable écrit du concepteur et développeur Jérôme
						Demuynck.
					</p>
					<p>
						L’ensemble du site y compris ses spécificités, relèvent de la
						législation française et internationale sur le droit d’auteur et la
						propriété intellectuelle. Tous les droits de reproduction sont
						réservés et sont soumis à une autorisation préalable et expresse du
						concepteur et développeur Jérôme Demuynck. Cette utilisation
						doit mentionner explicitement et obligatoirement la référence au
						concepteur et développeur Jérôme Demuynck, ainsi que les url des
						sites sources de l'article.
					</p>
					<p>
						Toute représentation et/ou exploitation, intégrale ou partielle, du
						site Répertoire SIAE Lens-Liévin Hénin-Carvin est soumise à une
						autorisation préalable et expresse du PLIE de Lens-Liévin
						Hénin-Carvin. Sans cette autorisation, elle est interdite et
						constituerait une contrefaçon au sens des articles L 335-2 et
						suivants du Code de la propriété intellectuelle.
					</p>
					<h3 className='box-legal-h3'>Images</h3>
					<p>
						Les images utilisées (logos) sont libres de droit à des fins de
						présentation des structures mises en avant.
					</p>{' '}
					<a
						target='_blank'
						rel='noreferrer'
						href='/#/contact'
						title='Vers la page de contact pour demander une autorisation'>
						Demandez l'autorisation
					</a>{' '}
					<br />
					<br />
					<p>
						La reproduction de contenus éditoriaux dans un but commercial ou
						publicitaire n'est pas autorisée.
					</p>
					<h3 className='box-legal-h3'>Liens hypertextes</h3>
					<p>
						Les liens pointant vers la page d'accueil ou l'une des pages du site
						répertoire SIAE peuvent être établis sans autorisation préalable.
						Dans ce cas la mention explicite du lien "Répertoire SIAE
						Lens-Liévin Hénin-Carvin" sera faite pour informer l'internaute de
						la teneur du lien.
					</p>
					<p>
						Cette autorisation tacite n'est pas valable pour les sites ayant un
						but commercial ou publicitaire voulant ainsi se prévaloir de la
						référence du site Répertoire SIAE Lens-Liévin Hénin-Carvin. Elle ne
						s'applique pas non plus aux sites internet diffusant des
						informations à caractère illicite, violent, polémique,
						pornographique, xénophobe ou pouvant porter atteinte à la
						sensibilité du plus grand nombre.
					</p>
					<h3 className='box-legal-h3'>Liens vers les documents</h3>
					<p>
						Il est recommandé de ne pas pointer directement vers les documents
						du site mais de pointer vers les pages les contenant. Le site
						Répertoire SIAE de Lens-Liévin Hénin-Carvin ne saurait être tenu
						responsable de l'utilisation abusive qui serait faite des contenus
						éditoriaux, photos, images, liens et documents hors des sites dont
						il est l'éditeur.
					</p>
				</article>
				<article>
					<h2 className='box-legal-article-title'>COOKIES</h2>
					<h3 className='box-legal-h3'>Qu'est-ce-qu'un cookie ?</h3>
					<p>
						Un cookie est un petit fichier texte déposé sur votre ordinateur
						lors de la visite d'un site. Ils ont pour but de collecter des
						informations relatives à votre navigation sur les sites et de vous
						adresser des services personnalisés. Dans votre ordinateur, les
						cookies sont gérés par votre navigateur internet.
					</p>
					<h3 className='box-legal-h3'>
						Quels sont les cookies utilisés sur le site siaellhc.fr ?
					</h3>
					<h4 className='box-legal-h4'>Fonctionnement du site siaellhc.fr</h4>
					<p>
						En cas de connexion à l'espace privé d'un compte enregistré, un
						token unique est déposé dans votre navigateur et permet de conserver
						la connexion à votre espace privé pendant votre navigation durant 4
						heures.
					</p>
					<p>
						Le site Répertoire SIAE Lens-Liévin Hénin-Carvin n'utilise aucun
						cookie pour son fonctionnement.
					</p>
				</article>
				<article>
					<h2 className='box-legal-article-title'>
						TRAITEMENT DES DONNÉES PERSONNELLES
					</h2>
					<h3 className='box-legal-h3'>Formulaire de contact</h3>
					<p>
						Les contacts du PLIE Lens-Lievin Hénin-Carvin sont gérés par
						formulaires électroniques. La totalité des informations renseignées
						est obligatoire (nom, prénom, email...).  Les données personnelles
						vous concernant sont recueillies au moyen des formulaires en ligne
						et sont destinées au service destinataire du message dans le seul
						but de répondre à la demande. Le traitement des données est
						nécessaire à l'exécution d'une mission d'intérêt public ou relevant
						de l'exercice de l'autorité publique dont est investi le responsable
						du traitement. Les données sont conservées par le service pendant le
						temps nécessaire à l'instruction de la demande. Elles seront
						supprimées trois mois après la date du dernier échange.
					</p>
					<h3 className='box-legal-h3'>
						Droit d'accès, de modification et de suppression aux informations
						vous concernant
					</h3>
					<p>
						Vous disposez d'un droit d'accès aux données personnelles
						collectées, à leur rectification, à leur suppression lorsque
						celles-ci ne sont plus nécessaires au regard des finalités pour
						lesquelles elles ont été collectées. Vous pouvez vous opposer au
						traitement de vos données pour motif légitime. Pour exercer vos
						droits vous pouvez écrire à :
					</p>
					<p className='address-bold'>
						PLIE de Lens-Liévin Hénin-Carvin
						<br />
						91 avenue jean Jaurès
						<br />
						62 800 Liévin
						<br />
					</p>
					<p>ou remplir le formulaire ci-dessous :</p>
					<p>
						<a
							target='_blank'
							rel='noreferrer'
							href='/#/contact'
							title='Vers la page de contact pour demander une modification de données'>
							Demander la modification, la rectification ou la suppression des
							données qui vous concernent.
						</a>
					</p>
				</article>
			</div>
		</main>
	);
};

export default LegalNotices;
