import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import CheckButton from 'react-validation/build/button';

import AuthService from '../../../../../services/auth/auth.service';
import StructureDataService from '../../../../../services/StructureService';

import { required } from '../../../../../utils/validation/fieldsCategorie';
import {
	vTitle,
	validEmail,
	vDescription,
	vTypeAgree,
	vLegalStatut,
	vDate,
	vSupervisor,
	vQuality,
	vContact,
	vAddress,
	vTel,
	vPort,
	// vFax,
	vWebsite,
	vProDomain,
	vCity,
	vClients,
	vNetworkMember,
} from '../../../../../utils/validation/fieldsStructure';
import FileUpload from '../../../../Upload/FileUpload';

import '../../Board/Structure/StructuresList.css';

const Structure = (props) => {
	const initialStructureState = {
		id: null,
		logo_url: '',
		sample_logo_file: '',
		title: '',
		description: '',
		type_of_agreement: '',
		legal_statut: '',
		creation_date: '',
		supervisor_identity: '',
		quality: '',
		contact_person: '',
		full_address: '',
		phone: '',
		mobile: '',
		fax: '',
		email: '',
		website: null,
		professionnal_domain: '',
		established_city: '',
		other_city: '',
		clients: '',
		network_membership: null,
		description_iae: '',
		career_opportunities: '',
		published: false,
	};
	const [currentStructure, setCurrentStructure] = useState(
		initialStructureState,
	);
	const [showAdminBoard, setShowAdminBoard] = useState(false);
	const [message, setMessage] = useState('');
	/* eslint-disable no-unused-vars */
	const [submitted, setSubmitted] = useState(false);

	const form = useRef();
	const checkBtn = useRef();

	const getStructure = (id) => {
		StructureDataService.get(id)
			.then((response) => {
				setCurrentStructure(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		const user = AuthService.getCurrentUser();
		if (user) {
			setShowAdminBoard(user.roles.includes('ROLE_ADMIN'));
		}
		console.log('useEffect props.match.params.id = ', props.match.params.id);
		getStructure(props.match.params.id);
	}, [props.match.params.id]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setCurrentStructure({ ...currentStructure, [name]: value });
	};

	const updatePublished = (status, e) => {
		e.preventDefault();
		setMessage('');
		setSubmitted(false);
		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			const data = {
				id: currentStructure.id,
				logo_url: currentStructure.logo_url,
				sample_logo_file: currentStructure.sample_logo_file,
				title: currentStructure.title,
				description: currentStructure.description,
				type_of_agreement: currentStructure.type_of_agreement,
				legal_statut: currentStructure.legal_statut,
				creation_date: currentStructure.creation_date,
				supervisor_identity: currentStructure.supervisor_identity,
				quality: currentStructure.quality,
				contact_person: currentStructure.contact_person,
				full_address: currentStructure.full_address,
				phone: currentStructure.phone,
				mobile: currentStructure.mobile,
				fax: currentStructure.fax,
				email: currentStructure.email,
				professionnal_domain: currentStructure.professionnal_domain,
				established_city: currentStructure.established_city,
				other_city: currentStructure.other_city,
				clients: currentStructure.clients,
				description_iae: currentStructure.description_iae,
				career_opportunities: currentStructure.career_opportunities,
				network_membership: currentStructure.network_membership,
				published: status,
			};

			console.log(
				'updatePublished currentStructure.id = ',
				currentStructure.id,
			);
			console.log('updatePublished data =', data);
			console.log('updatePublished currentStructure = ', currentStructure);

			StructureDataService.update(currentStructure.id, data)
				.then((response) => {
					setSubmitted(true);
					setCurrentStructure({ ...currentStructure, published: status });
					console.log(response.data);
					setMessage('Le status a été correctement mis à jour !');
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	const updateStructure = (e) => {
		e.preventDefault();
		setMessage('');
		setSubmitted(false);
		form.current.validateAll();

		console.log('updateStructure currentStructure.id = ', currentStructure.id);
		console.log('updateStructure currentStructure = ', currentStructure);

		if (checkBtn.current.context._errors.length === 0) {
			StructureDataService.update(currentStructure.id, currentStructure)
				.then((response) => {
					setSubmitted(true);
					console.log(response.data);
					setMessage('La structure a été correctement mise à jour !');
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	const deleteStructure = () => {
		console.log('deleteStructure currentStructure = ', currentStructure);
		console.log('deleteStructure currentStructure.id = ', currentStructure.id);

		StructureDataService.remove(currentStructure.id)
			.then((response) => {
				console.log(response.data);
				props.history.push('/structures');
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<div>
			{showAdminBoard ? (
				showAdminBoard && (
					<div>
						{currentStructure ? (
							<div id='top-tag' className='edit-form-initial'>
								<h4 className='pre-title'>Structure</h4>
								<Form ref={form}>
									<div className='form-group'>
										<label htmlFor='title'>Titre</label>
										<Input
											type='text'
											className='form-control'
											id='title'
											value={currentStructure.title}
											onChange={handleInputChange}
											name='title'
											validations={[required, vTitle]}
										/>
									</div>
									<div className='form-group'>
										<FileUpload />
									</div>
									<div className='form-group'>
										<label htmlFor='sample_logo_file'>Lien du Logo </label>
										<Input
											type='text'
											className='form-control'
											id='sample_logo_file'
											value={currentStructure.sample_logo_file}
											onChange={handleInputChange}
											name='sample_logo_file'
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='description'>Description</label>
										<Input
											type='text'
											className='form-control'
											id='description'
											name='description'
											value={currentStructure.description}
											onChange={handleInputChange}
											validations={[vDescription]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='type_of_agreement'>
											Type de conventionnement
										</label>
										<Input
											type='text'
											className='form-control'
											id='type_of_agreement'
											name='type_of_agreement'
											value={currentStructure.type_of_agreement}
											onChange={handleInputChange}
											validations={[vTypeAgree]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='legal_statut'>Statut juridique</label>
										<Input
											type='text'
											className='form-control'
											id='legal_statut'
											name='legal_statut'
											value={currentStructure.legal_statut}
											onChange={handleInputChange}
											validations={[vLegalStatut]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='creation_date'>Date de création</label>
										<Input
											type='text'
											className='form-control'
											id='creation_date'
											name='creation_date'
											value={currentStructure.creation_date}
											onChange={handleInputChange}
											validations={[vDate]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='supervisor_identity'>
											Nom et prénom du responsable
										</label>
										<Input
											type='text'
											className='form-control'
											id='supervisor_identity'
											name='supervisor_identity'
											value={currentStructure.supervisor_identity}
											onChange={handleInputChange}
											validations={[vSupervisor]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='quality'>Qualité</label>
										<Input
											type='text'
											className='form-control'
											id='quality'
											name='quality'
											value={currentStructure.quality}
											onChange={handleInputChange}
											validations={[vQuality]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='contact_person'>Personne à contacter</label>
										<Input
											type='text'
											className='form-control'
											id='contact_person'
											name='contact_person'
											value={currentStructure.contact_person}
											onChange={handleInputChange}
											validations={[vContact]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='full_address'>
											Adresse postale complète
										</label>
										<Input
											type='text'
											className='form-control'
											id='full_address'
											name='full_address'
											value={currentStructure.full_address}
											onChange={handleInputChange}
											validations={[vAddress]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='phone'>Téléphone</label>
										<Input
											type='text'
											className='form-control'
											id='phone'
											name='phone'
											value={currentStructure.phone}
											onChange={handleInputChange}
											validations={[vTel]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='mobile'>Portable</label>
										<Input
											type='text'
											className='form-control'
											id='mobile'
											name='mobile'
											value={currentStructure.mobile}
											onChange={handleInputChange}
											validations={[vPort]}
										/>
									</div>

									{/* <div className='form-group'>
										<label htmlFor='fax'>Fax</label>
										<Input
											type='text'
											className='form-control'
											id='fax'
											name='fax'
											value={currentStructure.fax}
											onChange={handleInputChange}
											validations={[vFax]}
										/>
									</div> */}

									<div className='form-group'>
										<label htmlFor='email'>Email</label>
										<Input
											type='text'
											className='form-control'
											id='email'
											name='email'
											value={currentStructure.email}
											onChange={handleInputChange}
											validations={[required, validEmail]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='website'>Site Internet</label>
										<Input
											type='text'
											className='form-control'
											id='website'
											name='website'
											value={currentStructure.website}
											onChange={handleInputChange}
											validations={[vWebsite]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='professionnal_domain'>
											Domaine professionnel
										</label>
										<Input
											type='text'
											className='form-control'
											id='professionnal_domain'
											name='professionnal_domain'
											value={currentStructure.professionnal_domain}
											onChange={handleInputChange}
											validations={[vProDomain]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='established_city'>
											Commune d'implantation
										</label>
										<Input
											type='text'
											className='form-control'
											id='established_city'
											name='established_city'
											value={currentStructure.established_city}
											onChange={handleInputChange}
											validations={[vCity]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='other_city'>Les autres sites</label>
										<Input
											type='text'
											className='form-control'
											id='other_city'
											name='other_city'
											value={currentStructure.other_city}
											onChange={handleInputChange}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='clients'>Clients</label>
										<Input
											type='text'
											className='form-control'
											id='clients'
											name='clients'
											value={currentStructure.clients}
											onChange={handleInputChange}
											validations={[vClients]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='network_membership'>
											Appartenance à un groupe en réseau
										</label>
										<Input
											type='text'
											className='form-control'
											id='network_membership'
											name='network_membership'
											value={currentStructure.network_membership}
											onChange={handleInputChange}
											validations={[vNetworkMember]}
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='description_iae'>
											Description de l'activité économique de la structure
										</label>
										<Textarea
											className='form-control'
											id='description_iae'
											value={currentStructure.description_iae}
											onChange={handleInputChange}
											name='description_iae'
											rows={5}
											cols={8}
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='career_opportunities'>
											Débouchés professionnels possibles pour les salariés en
											insertion
										</label>
										<Textarea
											className='form-control'
											id='career_opportunities'
											value={currentStructure.career_opportunities}
											onChange={handleInputChange}
											name='career_opportunities'
											rows={5}
											cols={8}
										/>
									</div>
									<div className='form-group'>
										<label>
											<strong>Statut:</strong>
										</label>
										{currentStructure.published ? 'Publié' : 'En attente'}
									</div>
									<CheckButton style={{ display: 'none' }} ref={checkBtn} />
								</Form>

								<div className='add-tuto-bottom top-45'>
									{currentStructure.published ? (
										<button
											className='badge badge-primary mr-3'
											onClick={() => updatePublished(false)}>
											Dépublier
										</button>
									) : (
										<button
											className='badge badge-primary mr-3'
											onClick={() => updatePublished(true)}>
											Publier
										</button>
									)}

									<button
										className='badge badge-danger mr-3'
										onClick={() => {
											const confirmBox = window.confirm(
												'Confirmez-vous la suppression de cette structure ?',
											);
											if (confirmBox === true) {
												deleteStructure();
											}
										}}>
										Supprimer
									</button>

									<button
										type='submit'
										className='badge badge-success mt-10'
										onClick={updateStructure}>
										Valider
									</button>
									<p>{message}</p>
								</div>
							</div>
						) : (
							<div>
								<br />
								<p>Veuillez cliquer sur une structure...</p>
							</div>
						)}
					</div>
				)
			) : (
				<div className='jumbotron jumbotron-top'>
					Vous ne disposez pas des droits pour atteindre cette page privée.
				</div>
			)}
		</div>
	);
};

export default Structure;
