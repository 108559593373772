import { useState, useRef } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import CheckButton from 'react-validation/build/button';
import ReCAPTCHA from 'react-google-recaptcha'; // library to adapt Google reCAPTCHA v2

import ContactService from '../../../services/ContactService';
import { required } from '../../../utils/validation/fieldsCategorie';
import {
	vLastName,
	vFirstName,
	vTel,
	validEmail,
	vSubject,
	vMessage,
} from '../../../utils/validation/fieldsStructure';

import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactForm.css';

const ContactForm = () => {
	const initialContactFormState = {
		id: null,
		firstName: '',
		lastName: '',
		phoneNumber: '',
		email: '',
		subject: '',
		message: '',
	};

	const [contactForm, setContactForm] = useState(initialContactFormState);
	const [submitted, setSubmitted] = useState(false);

	const form = useRef();
	const checkBtn = useRef();
	const recaptchaRef = useRef(null);

	const [messageInfo, setMessageInfo] = useState('');
	const [captchaStatus, setCaptchaStatus] = useState({
		success: false,
		message: '',
	});

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setContactForm({ ...contactForm, [name]: value });
	};

	const newContactForm = () => {
		setContactForm(initialContactFormState);
		setSubmitted(false);
	};

	const submitForm = async (e) => {
		e.preventDefault();
		setMessageInfo('');
		setSubmitted(false);
		form.current.validateAll();

		const captchaValue = recaptchaRef.current.getValue();
		recaptchaRef.current.reset(); // reset the component after every submission

		const response = await fetch('https://www.siaellhc.fr/verify', {
			headers: {
				'content-type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({
				captchaValue,
				contactForm,
			}),
		});

		console.log(
			'Formulaire : ',
			contactForm.firstName,
			contactForm.lastName,
			contactForm.phoneNumber,
			contactForm.email,
			contactForm.subject,
			contactForm.message,
		);

		const captchaValidation = await response.json();
		setCaptchaStatus(captchaValidation);

		if (checkBtn.current.context._errors.length === 0) {
			const data = {
				firstName: contactForm.firstName,
				lastName: contactForm.lastName,
				phoneNumber: contactForm.phoneNumber,
				email: contactForm.email,
				subject: contactForm.subject,
				message: contactForm.message,
			};

			ContactService.create(data)
				.then(
					(response) => {
						console.log(response);
						setMessageInfo(response.data.messageInfo);
						setSubmitted(true);
						setContactForm({
							firstName: response.data.firstName,
							lastName: response.data.lastName,
							phoneNumber: response.phoneNumber,
							email: response.data.email,
							subject: response.data.subject,
							message: response.data.message,
						});
					},
					(error) => {
						const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.messageInfo) ||
							error.messageInfo ||
							error.toString();

						setMessageInfo(resMessage);
						setSubmitted(false);
					},
				)
				.catch((e) => {
					console.log(e);
				});
		}
	};

	return (
		<div className='box-form'>
			{submitted && captchaStatus.message === 'Le captcha a été validé.' ? (
				<p className='box-form-submitted-msg'>
					Votre message a bien été envoyé ! Nous vous recontacterons après le
					traitement de votre demande.
					<div className='box-form-btn'>
						<button className='btn btn-success' onClick={newContactForm}>
							Envoyer un nouveau message
						</button>
					</div>
				</p>
			) : (
				<>
					<div>
						<h1 className='box-form-title'>Contactez-nous</h1>
						<p className='box-form-subtitle'>
							Merci de compléter le formulaire ci-dessous pour toutes demandes
							ou requêtes de rectification de données.
						</p>
					</div>
					<Form onSubmit={submitForm} className='box-form-contact' ref={form}>
						<h3>Coordonnées</h3>
						<div className='form-group row'>
							<label
								htmlFor='lastName'
								className='box-form-label col-sm-4 col-form-label'>
								Nom<span className='label-required'>*</span>
							</label>
							<div className='col-sm-12'>
								<Input
									type='text'
									className='box-form-input form-control'
									id='lastName'
									value={contactForm.lastName}
									onChange={handleInputChange}
									name='lastName'
									validations={[required, vLastName]}
								/>
							</div>
							<label
								htmlFor='firstName'
								className='box-form-label col-sm-4 col-form-label'>
								Prénom<span className='label-required'>*</span>
							</label>
							<div className='col-sm-12'>
								<Input
									type='text'
									className='box-form-input form-control'
									id='firstName'
									value={contactForm.firstName}
									onChange={handleInputChange}
									name='firstName'
									validations={[required, vFirstName]}
								/>
							</div>
						</div>
						<div className='form-group row'>
							<label
								htmlFor='phoneNumber'
								className='box-form-label col-sm-4 col-form-label'>
								Téléphone<span className='label-required'>*</span>
							</label>
							<div className='col-sm-12'>
								<Input
									type='text'
									className='box-form-input form-control'
									id='phoneNumber'
									value={contactForm.phoneNumber}
									onChange={handleInputChange}
									name='phoneNumber'
									validations={[required, vTel]}
								/>
							</div>
						</div>
						<div className='form-group row'>
							<label
								htmlFor='email'
								className='box-form-label col-sm-4 col-form-label'>
								Email<span className='label-required'>*</span>
							</label>
							<div className='col-sm-12'>
								<Input
									type='text'
									className='box-form-input form-control'
									id='email'
									value={contactForm.email}
									aria-describedby='emailHelp'
									onChange={handleInputChange}
									name='email'
									validations={[required, validEmail]}
								/>
							</div>
						</div>
						<h3>Message</h3>
						<div className='form-group row'>
							<label
								htmlFor='subject'
								className='box-form-label col-sm-4 col-form-label'>
								Objet<span className='label-required'>*</span>
							</label>
							<div className='col-sm-12'>
								<Input
									type='text'
									className='box-form-input form-control'
									id='subject'
									value={contactForm.subject}
									onChange={handleInputChange}
									name='subject'
									validations={[required, vSubject]}
								/>
							</div>
						</div>
						<div className='mb-3'>
							<label className='box-form-label' htmlFor='message'>
								Message<span className='label-required'>*</span>
							</label>
							<Textarea
								className='form-control'
								id='message'
								value={contactForm.message}
								onChange={handleInputChange}
								name='message'
								rows={5}
								min={8}
								validations={[required, vMessage]}
							/>
						</div>
						<div className='box-captcha'>
							{/*https://www.google.com/recaptcha/admin/site/689247448/settings*/}
							<ReCAPTCHA
								ref={recaptchaRef}
								sitekey={process.env.REACT_APP_SITE_KEY}
								/*
									optional props of the reCAPTCHA component :
										- theme: changes the widget’s theme to light or dark
										- size: changes the size or type of CAPTCHA
										- onErrored: fires a callback function if the test returns an error
										-badge: changes the position of the reCAPTCHA badge (bottomright, bottomleft, or inline)
								*/
							/>
						</div>
						<p className='box-form-captcha-msg'>{captchaStatus.message}</p>
						<button
							className='box-form-btn btn btn-success btn-primary'
							type='submit'>
							Envoyer le formulaire
						</button>
						{messageInfo && (
							<div className='form-group'>
								<div
									className={
										submitted ? 'alert alert-success' : 'alert alert-danger'
									}
									role='alert'>
									{messageInfo}
								</div>
							</div>
						)}
						<CheckButton style={{ display: 'none' }} ref={checkBtn} />
					</Form>
					<div className='m-4 form-footer'>
						Conformément à la loi Informatique et Libertés du 6 janvier 1978
						réformée, vous disposez d'un droit d’accès, de modification, de
						rectification et de suppression des données vous concernant. Afin
						d'exercer ce droit nous vous invitons à nous contacter.
					</div>
				</>
			)}
		</div>
	);
};

export default ContactForm;
