import React, { useState } from 'react';

import logoPlieLievin from '../../../../logos/logo_plie.jpeg';
import logoEurope from '../../../../logos/logo_ue_cofinance.jpg';

import '../../HomePage/homePage.css';

const LogosHeader = () => {
	const [loading, setLoading] = useState(true);

	const handleLoad = () => {
		setLoading(false);
	};

	return (
		<div className='home__logo--header'>
			{loading && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-border text-primary' role='status'>
						<span class='sr-only'>Loading...</span>
					</div>
				</div>
			)}
			<img
				className='logo europe-fr'
				src={logoEurope}
				alt="Logo L'Europe cofinance"
				onLoad={handleLoad}
			/>
			<img
				className='logo plie-lievin'
				src={logoPlieLievin}
				alt='Logo du Plie de Liévin'
				onLoad={handleLoad}
			/>
		</div>
	);
};

export default LogosHeader;
