import http from '../http-common';

const create = (data) => {
	return http.post('/contacts', data);
};

const exportedContactFormObject = {
	create,
};

export default exportedContactFormObject;
