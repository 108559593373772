import React, {useState} from 'react';

import TreeBooks from './glossary_tree_books.jpg';

import './glossary.css';

const Glossary = () => {
	const [loading, setLoading] = useState(true);

	const handleLoad = () => {
		setLoading(false);
	};

	return (
		<div className='home end__glossaire' id='cloud-container'>
			<div className='home__logo--header glossaire--bordure'>
				<div className='editor__head--bordure'></div>
				<div>
					<h2 className='title-glossary'>Glossaire</h2>
					<div className='glossaire__content'>
						<ul className='acronyms'>
							<li>
								<span className='bold'>ACI</span> : Ateliers et Chantiers
								d’Insertion
							</li>
							<li>
								<span className='bold'>AI</span> : Associations Intermédiaires
							</li>
							<li>
								<span className='bold'>EI</span> : Entreprises d’Insertion
							</li>
							<li>
								<span className='bold'>ETTI</span> : Entreprises de Travail
								Temporaires d’Insertion
							</li>
							<li>
								<span className='bold'>IAE</span> : Insertion par l’Activité
								Économique
							</li>
							<li>
								<span className='bold'>SIAE</span> : Structure d’Insertion par
								l’Activité Économique
							</li>
						</ul>
						<ul className='acronyms'>
							<li>
								<span className='bold'>DDETS</span> : Direction Départementale
								de l’Emploi, du Travail et des Solidarités du Pas de Calais
							</li>
							<li>
								<span className='bold'>PLIE</span> : Plan Local pour l’Insertion
								et l’Emploi
							</li>
							<li>
								<span className='bold'>CHRS</span> : Centre d’Hébergement et de
								Réinsertion Social
							</li>
							<li>
								<span className='bold'>CTAL</span> : Comité Technique
								d’Animation Local
							</li>
						</ul>
						<ul className='acronyms'>
							<li>
								<span className='bold'>CDDI</span> : Contrat à Durée Déterminée
								d’Insertion
							</li>
							<li>
								<span className='bold'>CDI</span> : Contrat à Durée Indéterminée
							</li>
							<li>
								<span className='bold'>CDD</span> : Contrat à Durée Déterminée
							</li>
						</ul>
						{loading && (
							<div class='d-flex justify-content-center'>
								<div class='spinner-border text-primary' role='status'>
									<span class='sr-only'>Loading...</span>
								</div>
							</div>
						)}
						<img
							className='logo tree_books'
							src={TreeBooks}
							alt='Arbre de livres'
							title='Arbre de livres'
							onLoad={handleLoad}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Glossary;
