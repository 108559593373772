import http from '../http-common';

const getAll = (params) => {
	return http.get('/categories', { params });
};

const get = (id) => {
	return http.get(`/categories/${id}`);
};

const create = (data) => {
	return http.post('/categories', data);
};

const update = (id, data) => {
	console.log(id);
	console.log(data);
	return http.put(`/categories/${id}`, data);
};

const remove = (id) => {
	console.log(id);
	return http.delete(`/categories/${id}`);
};

const findByTitle = (title) => {
	return http.get(`/categories?title=${title}`);
};

const exportedCategoryObject = {
	getAll,
	get,
	create,
	update,
	remove,
	findByTitle,
};

export default exportedCategoryObject;
