import React, {useState, useEffect} from 'react';
import {Switch, Route, NavLink} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import './App.css';

import AuthService from './services/auth/auth.service';

import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Profile from './components/auth/Profile';
import BoardUser from './components/auth/BoardUser';
import BoardModerator from './components/auth/BoardModerator';
import BoardAdmin from './components/auth/BoardAdmin';
import BoardTuto from './components/auth/BoardTuto';

import Edito from './components/pages/Edito/Edito';
import InitMap from './components/pages/Map/InitMap';
import Glossary from './components/pages/Glossary/Glossary';
import HomePage from './components/pages/HomePage/HomePage';
import LesPlies from './components/pages/LesPlies/LesPlies';
import Iae from './components/pages/Iae/Iae';
import LegalFramework from './components/pages/LegalFramework/LegalFramework';
import Thanks from './components/pages/Thanks/Thanks';

import addCategory from './components/pages/DashBoard/Form/Category/AddCategory';
import Category from './components/pages/DashBoard/Form/Category/Category';
import CategoriesList from './components/pages/DashBoard/Board/Category/CategoriesList';

import addStructure from './components/pages/DashBoard/Form/Structure/AddStructure';
import Structure from './components/pages/DashBoard/Form/Structure/Structure';
import StructuresList from './components/pages/DashBoard/Board/Structure/StructuresList';

import AssoIntermed from './components/pages/Assos/AssoIntermed';
import AteliersChantiers from './components/pages/Assos/AteliersChantiers';
import EntreprisesInsertion from './components/pages/Assos/EntreprisesInsertion';
import EntreprisesTravailTempo from './components/pages/Assos/EntreprisesTravailTempo';

import StructureAI from './components/pages/Assos/Structures/StructureAI';
import StructureACI from './components/pages/Assos/Structures/StructureACI';
import StructureEI from './components/pages/Assos/Structures/StructureEI';
import StructureETTI from './components/pages/Assos/Structures/StructureETTI';

import ContactForm from './components/pages/ContactForm/ContactForm';
import LegalNotices from './components/pages/LegalNotices/LegalNotices';

import Hello from './components/pages/Hello/Hello';
import NotFound from './components/pages/NotFound/NotFound';
import TableExample from './components/pages/Hello/TableExample';
import Footer from './components/pages/Footer/Footer';

const App = () => {
	const [showModeratorBoard, setShowModeratorBoard] = useState(false);
	const [showAdminBoard, setShowAdminBoard] = useState(false);
	const [currentUser, setCurrentUser] = useState(undefined);

	useEffect(() => {
		const user = AuthService.getCurrentUser();
		window.scrollTo(0, 0);
		if (user) {
			setCurrentUser(user);
			setShowModeratorBoard(user.roles.includes('ROLE_MODERATOR'));
			setShowAdminBoard(user.roles.includes('ROLE_ADMIN'));
		}
	}, []);

	const logOut = () => {
		AuthService.logout();
	};

	return (
		<div className='app-container'>
			<nav className='navbar fixed-top navbar-expand-lg navbar-light bg-perso'>
				<div className='navbar-nav mr-auto'>
					<NavLink to={'/'} className='navbar-brand'>
						<span className='navbar__title'>Répertoire SIAE</span>
					</NavLink>
					<button
						className='navbar-toggler toggle-size'
						type='button'
						data-toggle='collapse'
						data-target='#navbarNavDropdown'
						aria-controls='navbarNavDropdown'
						aria-expanded='false'
						aria-label='Toggle navigation'>
						<span className='navbar-toggler-icon'></span>
					</button>

					<div className='collapse navbar-collapse' id='navbarNavDropdown'>
						<ul className='navbar-nav nav-string'>
							<li className='nav-item active dropdown'>
								<NavLink
									to={'/'}
									className='nav-link dropdown-toggle'
									id='navbarDropdownMenuLink'
									role='button'
									data-toggle='dropdown'
									aria-haspopup='true'
									aria-expanded='false'>
									Accueil <span className='sr-only'>(current)</span>
								</NavLink>
								<div
									className='dropdown-menu'
									aria-labelledby='navbarDropdownMenuLink'>
									<NavLink to={'/iae'} className='dropdown-item'>
										Définition de l'I.A.E.
									</NavLink>
									<NavLink to={'/cadreJuri'} className='dropdown-item'>
										Public cible et cadre juridique de l'I.A.E.
									</NavLink>
									<NavLink to={'/glossaire'} className='dropdown-item'>
										Glossaire
									</NavLink>
									<NavLink to={'/remerciements'} className='dropdown-item'>
										Remerciements
									</NavLink>
								</div>
							</li>
							<li className='nav-item dropdown'>
								<NavLink
									to={'/lesPlies'}
									className='nav-link dropdown-toggle'
									id='navbarDropdownMenuLink'
									role='button'
									data-toggle='dropdown'
									aria-haspopup='true'
									aria-expanded='false'>
									Qui sommes-nous ?
								</NavLink>
								<div
									className='dropdown-menu'
									aria-labelledby='navbarDropdownMenuLink'>
									<NavLink to={'/edito'} className='dropdown-item'>
										Éditorial
									</NavLink>
									<NavLink to={'/lesPlies'} className='dropdown-item'>
										Les Plies
									</NavLink>
								</div>
							</li>
							<li className='nav-item dropdown'>
								<NavLink
									to={'/'}
									className='nav-link dropdown-toggle'
									id='navbarDropdownMenuLink'
									role='button'
									data-toggle='dropdown'
									aria-haspopup='true'
									aria-expanded='false'>
									Les Structures
								</NavLink>
								<div
									className='dropdown-menu'
									aria-labelledby='navbarDropdownMenuLink'>
									<NavLink to={'/ai'} className='dropdown-item'>
										A.I.
									</NavLink>
									<NavLink to={'/aci'} className='dropdown-item'>
										A.C.I.
									</NavLink>
									<NavLink to={'/ei'} className='dropdown-item'>
										E.I.
									</NavLink>
									<NavLink to={'/etti'} className='dropdown-item'>
										E.T.T.I.
									</NavLink>
								</div>
							</li>
							<li>
								<NavLink to={'/map'} className='nav-link'>
									Cartographie
								</NavLink>
							</li>
							<li>
								<NavLink to={'/contact'} className='nav-link'>
									Contact
								</NavLink>
							</li>
							{showAdminBoard && (
								<li className='nav-item dropdown'>
									<NavLink
										to={'/admin'}
										className='nav-link dropdown-toggle'
										id='navbarDropdownMenuLink'
										role='button'
										data-toggle='dropdown'
										aria-haspopup='true'
										aria-expanded='false'>
										<strong>Panneau d'admin</strong>
									</NavLink>
									<div
										className='dropdown-menu'
										aria-labelledby='navbarDropdownMenuLink'>
										<NavLink to={'/admin'} className='dropdown-item'>
											Panneau d'administration
										</NavLink>
										<NavLink to={'/categories'} className='dropdown-item'>
											Categories
										</NavLink>
										<NavLink to={'/structures'} className='dropdown-item'>
											Structures
										</NavLink>
									</div>
								</li>
							)}
							{showModeratorBoard && (
								<li className='nav-item hide'>
									<NavLink to={'/modo'} className='nav-link'>
										<strong>Contenu Modoérateur</strong>
									</NavLink>
								</li>
							)}
							{currentUser && (
								<li className='nav-item hide'>
									<NavLink to={'/user'} className='nav-link'>
										<strong>Contenu Utilisateur</strong>
									</NavLink>
								</li>
							)}
						</ul>
					</div>
				</div>

				{currentUser ? (
					<div className='navbar-nav ml-auto nav-string'>
						<li className='nav-item'>
							<NavLink to={'/profile'} className='nav-link bold'>
								{currentUser.username}
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink to={'/login'} className='nav-link' onClick={logOut}>
								{' '}
								Se déconnecter
							</NavLink>
						</li>
					</div>
				) : (
					<div className='navbar-nav ml-auto blabla'>
						<li className='nav-item'>
							<NavLink to={'/register'} className='nav-link'>
								{' '}
								S'enregistrer
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink to={'/login'} className='nav-link'>
								{' '}
								Se connecter
							</NavLink>
						</li>
					</div>
				)}
			</nav>

			<div>
				<Switch>
					<Route exact path={['/', 'home']} component={HomePage} />
					<Route path='/login' component={Login} />
					<Route path='/register' component={Register} />
					<Route path='/profile' component={Profile} />
					<Route path='/user' component={BoardUser} />
					<Route path='/modo' component={BoardModerator} />
					<Route path='/admin' component={BoardAdmin} />
					<Route path='/tutoUploadImg' component={BoardTuto} />

					<Route path='/map' component={InitMap} />
					<Route path='/glossaire' component={Glossary} />
					<Route path='/remerciements' component={Thanks} />
					<Route path='/edito' component={Edito} />
					<Route path='/lesPlies' component={LesPlies} />
					<Route path='/iae' component={Iae} />
					<Route path='/cadreJuri' component={LegalFramework} />
					<Route exact path='/contact' component={ContactForm} />
					<Route exact path='/mentions' component={LegalNotices} />

					{/* Partie publique des categories & structures */}
					<Route exact path='/ai' component={AssoIntermed} />
					<Route exact path='/ai/:id' component={StructureAI} />
					<Route exact path='/aci' component={AteliersChantiers} />
					<Route exact path='/aci/:id' component={StructureACI} />
					<Route exact path='/ei' component={EntreprisesInsertion} />
					<Route exact path='/ei/:id' component={StructureEI} />
					<Route exact path='/etti' component={EntreprisesTravailTempo} />
					<Route exact path='/etti/:id' component={StructureETTI} />

					<Route exact path='/categories' component={CategoriesList} />
					<Route exact path='/nouvelleCategorie' component={addCategory} />
					<Route exact path='/categories/:id' component={Category} />

					<Route exact path='/structures' component={StructuresList} />
					<Route exact path='/nouvelleStructure' component={addStructure} />
					<Route exact path='/structures/:id' component={Structure} />

					{/* Partie Test & 404 */}
					<Route exact path='/testTable' component={TableExample} />
					<Route exact path='/hello' component={Hello} />
					<Route component={NotFound} />
				</Switch>
			</div>
			<div className='app-footer'>
				<Footer />
			</div>
		</div>
	);
};

export default App;
