// Validation de nombre de caractères pour les champs du formulaire
let minLen = 1;

export const validData = (value, words, maxLen) => {
	if (value.length < minLen || value.length > maxLen) {
		return (
			<div className='alert alert-danger' role='alert'>
				{`Le champ "${words}" doit posséder entre ${minLen} et ${maxLen} caractères.`}
			</div>
		);
	}
};

export const required = (value) => {
	if (!value) {
		return (
			<div className='alert alert-danger' role='alert'>
				Ce champs est requis.
			</div>
		);
	}
};

export const vTitle = (value) => {
	let maxLen = 10;
	let words = 'Titre';
	return validData(value, words, maxLen);
};

export const vSubtitle = (value) => {
	let maxLen = 50;
	let words = 'Sous-titre';
	return validData(value, words, maxLen);
};

export const vQuestion = (value) => {
	let maxLen = 100;
	let words = 'Question';
	return validData(value, words, maxLen);
};

export const vDescription = (value) => {
	if (value.length < minLen) {
		return (
			<div className='alert alert-danger' role='alert'>
				Le champ "Description" doit posséder au moins 1 caractère.
			</div>
		);
	}
};
