import React from 'react';

import LogosHeader from '../Logos/Header/LogosHeader';

import '../Edito/edito.css';

const Edito = () => {
	return (
		<div className='home edito--bottom' id='cloud-container'>
			<div className='edito--bordure'>
				<div className='edito__head--bordure'></div>
				<div className='edito__logos'>
					<LogosHeader />
				</div>

				<div>
					<h2 className='edito__title'>EDITORIAL</h2>
					<div className='edito__content'>
						<p>
							L’insertion par l’activité économique est fortement développée
							dans notre bassin d’emploi. Fruit d’un travail, souvent
							désintéressé, d’hommes et de femmes qui placent l’humain au cœur
							de leur préoccupation, cette forme d’économie tient toute sa place
							au sein du développement local et de l’Economie Sociale et
							Solidaire par son caractère non délocalisable.
						</p>
						<p>
							L’insertion par l’activité économique permet à des publics
							éloignés du marché de l’emploi d’acquérir une expérience
							professionnelle réelle, bien souvent la première, de prendre ou de
							reprendre des habitudes de travail, de donner de nouvelles
							perspectives à leur vie personnelle.
						</p>
						<p>
							Les structures présentées dans ce répertoire agissent dans des
							domaines professionnels très variés : industrie, transport,
							tertiaire, environnement, bâtiment, … Elles permettent à plus de
							3 000 personnes chaque année de bénéficier d’un contrat le plus
							souvent accompagné d’une formation.
						</p>
						<p>
							Ces dernières années, l’insertion par l’activité économique a
							connu de nombreux bouleversements qui l’ont fortement
							déstabilisée : changement de contrats, modalités de financements,
							durcissement des critères d’évaluation, …
						</p>
						<p>
							Souhaitons, en ces temps de crise économique, que le cadre de
							fonctionnement de ces structures soit mieux reconnu.
						</p>
						<p>
							Les PLIEs de Lens-Liévin et Hénin-Carvin, pour leur part avec tous
							leurs partenaires et en particulier l’Etat (DDETS), France
							Travail, la Région Hauts-de-France et le Département du
							Pas-de-Calais sont attentifs au devenir de ces activités.
						</p>
						<p>
							Les exigences qui sont les leurs, parfois imposées de l’extérieur,
							n’ont pour seule finalité que le développement qualitatif et
							quantitatif des actions. C’est ainsi que l’on pourra poursuivre ce
							travail qui contribue à l’insertion des plus démunis.
						</p>
					</div>
					<div className='edito__signature--block'>
						<p className='edito__signature sign__left'>
							Le Président du PLIE de Lens-Liévin <br />
							M. Jérôme DARRAS
						</p>
						<p className='edito__signature sign__right'>
							Le Président du PLIE d’Hénin-Carvin <br />
							M. Jean-François THERET
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Edito;
