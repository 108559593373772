import React from 'react';

import '../LesPlies/lesplies.css';

const LesPlies = () => {
	return (
		<div className='home end__lesPlies' id='cloud-container'>
			<div className='home__left edito__left'></div>
			<div className='home__right'></div>
			<div className='home__logo--header lesPlies--bordure'>
				<div className='editor__head--bordure'></div>
				<div className='page__content--lesPlies'>
					<h3 className='lesPlies__title'>
						Les PLIEs et l’Insertion par l’Activité Economique
					</h3>
					<div className='lesPlies__content'>
						<p>
							L’Insertion par L’activité Economique (IAE) est l’un des axes
							d’intervention des Plans Locaux pour l’Insertion et l’Emploi
							(PLIE). Le développement de ce secteur, la création de nouvelles
							entreprises ou associations œuvrant dans ce champ sont des
							priorités affichées dans les protocoles de mise en œuvre des PLIEs
							de Lens-Liévin et d’Hénin-Carvin. Par ailleurs, le soutien
							qualitatif aux actions d’insertion se traduit par le renforcement
							de l’encadrement socioprofessionnel des structures agréées dans le
							cadre de l’Insertion par l’Activité Economique.
						</p>
						<p>Ainsi, les PLIEs agissent sur :</p>
						<p className='first__arg'>
							<strong>
								1- L’appui à la création, au développement, à la restructuration
								et à la consolidation des Structures d’Insertion par l’Activité
								Economique :
							</strong>
						</p>
						<p>
							Le PLIE s’attache à susciter et à encourager les initiatives de
							développement ou de création de SIAE. Le développement de l’IAE
							passe aussi par une meilleure connaissance du dispositif. Sa
							promotion peut aussi contribuer à son essor. Notre objectif est de
							renforcer cette promotion afin que d’autres initiatives puissent
							voir le jour et se multiplier.<br></br> Il en fait également la
							promotion à l’échelon du bassin d’emploi. Le PLIE veille aussi, à
							consolider les structures existantes afin de conserver le tissu de
							l’insertion par l’activité économique local.
							<br /> Le PLIE reste en permanence à l’écoute des entrepreneurs de
							l’IAE, afin de les accompagner et de les aider dans toutes les
							étapes du projet de création, de développement, de restructuration
							ou de consolidation.
						</p>
						<p className='first__arg'>
							<strong>2- Le renforcement :</strong>
						</p>
						<p>
							Le PLIE intervient dans le renforcement de l’encadrement
							socio-professionnel des salariés en insertion. Dans ce cadre, les
							structures conventionnées valorisent les étapes entreprises par
							les participants. Pour cela, il est nécessaire qu’elles mobilisent
							les outils mis à leur disposition, pour favoriser la construction
							du parcours et l’accès à l’emploi des participants.
							<br /> Le PLIE est attentif aux sollicitations des structures IAE
							en matière de formations individuelles ou collectives, en veillant
							à ce que la cohérence s’inscrive dans le parcours et que les
							formations proposées aux salariés en insertion soient pertinentes
							au regard du projet et des débouchés sur le marché du travail et
							ce, afin d’optimiser les parcours.
						</p>
						<p className='first__arg'>
							<strong>3- Les Clauses d’insertion :</strong>
						</p>
						<p>
							Les clauses d’insertion dans les marchés publics favorisent la
							promotion de l’emploi, la lutte contre l’exclusion et la
							précarité. Les maîtres d’ouvrages (Département, Collectivités,
							Etablissements publics, EPCI, ...) peuvent fixer des exigences
							sociales dans l’exécution des marchés publics. Ils déterminent la
							mise en place de la clause d’insertion en fonction de l’objet, la
							durée et le montant du marché.
						</p>
						<ul>
							{' '}
							Les PLIE(s) des Communautés d’Agglomérations de Lens Liévin et
							d’Hénin Carvin :
							<li className='list-lesPlies'>
								{'  '}
								Facilitent la gestion et la mise en œuvre des clauses
								d’insertion. Ils sont l’interface entre les donneurs d’ordres,
								les entreprises et les acteurs de l’emploi et de l’insertion du
								territoire.
							</li>
							<li className='list-lesPlies'>
								{'  '}
								Proposent les structures de l’insertion par l’activité
								économique comme partenaires aux entreprises, pour la
								sous-traitance, la co-traitance ou la mutualisation des heures
								d’insertion.
							</li>
							<li className='list-lesPlies'>
								{'  '}
								Proposent aux entreprises leurs services de présélection et de
								présentation des candidats pour une embauche directe.
							</li>
							<li className='list-lesPlies'>
								{'  '}
								S’assurent de l’application de la clause d’insertion par les
								entreprises et en évaluent les résultats.
							</li>
						</ul>
						<p className='first__arg'>
							<strong>
								4– Le développement du réseau et la promotion de l’ IAE :
							</strong>
						</p>
						<p>
							Des rencontres régulières d’échanges et d’informations entre les
							différentes structures du bassin d’emploi sont animées chaque
							trimestre, sous la forme d’un Comité Technique d’Animation Local
							(CTAL), instance co-animée avec France Travail et en partenariat
							avec les services de l’Etat (
							<span
								title='Direction Départementale de
              l’Emploi, du Travail et des Solidarités du Pas de Calais'>
								<em>DDETS</em>
							</span>
							), la Région Hauts-de-France et le Département .
						</p>
						<p className='coord-de'>Les coordonnées de :</p>

						<ul className='list__block-coord'>
							<li className='list__coord plie-ll'>
								<p className='list__coord--begin bold'>
									<span className='plie'>
										PLIE de Lens-Liévin / Hénin-Carvin
									</span>
									<span className='coord__span'>
										91 rue Jean Jaurès,
										<br />
										62800 LIEVIN
										<br /> Tél : 03 21 08 72 10
										<br />
										Courriel :{' '}
										<a href='mailto:plielenslievin@plie-llhc.fr'>
											plielenslievin@plie-llhc.fr
										</a>
									</span>
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LesPlies;
