import axios from 'axios';

// const PORT = process.env.PORT || 8080;

//  initializes axios with HTTP base Url and headers.
export default axios.create({
	// baseURL: 'http://localhost:8080/api', // dev
	// baseURL: 'http://localhost:5000/api', // test prod 1
	// baseURL: 'https://siae-ll-hc-app.herokuapp.com/api', // prod heroku
	// baseURL: `https://samsara.live/siae/api`, // prod namecheap
	baseURL: `https://www.siaellhc.fr/api`,
	headers: {
		'Content-type': 'application/json',
	},
});
