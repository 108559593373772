import React from 'react';
import { Link } from 'react-router-dom';

import '../Hello/Hello';

const NotFound = () => (
	<div className='message-top'>
		<h2>
			<strong className='message-top'>Erreur 404 - chemin non trouvé!</strong>
		</h2>
		<Link to='/'>
			Se rendre sur la <em>page d'accueil</em>
		</Link>
	</div>
);

export default NotFound;
