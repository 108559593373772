import React, { useState, useRef } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { isEmail } from 'validator';

import AuthService from '../../services/auth/auth.service';

const required = (value) => {
	if (!value) {
		return (
			<div className='alert alert-danger' role='alert'>
				Ce champs est requis !
			</div>
		);
	}
};

const validEmail = (value) => {
	if (!isEmail(value)) {
		return (
			<div className='alert alert-danger' role='alert'>
				L'adresse courriel n'est pas valide !
			</div>
		);
	}
};

const validUsername = (value) => {
	if (value.length < 3 || value.length > 25) {
		return (
			<div className='alert alert-danger' role='alert'>
				Le nom de l'utilisateur doit comprendre entre 3 et 25 caractères.
			</div>
		);
	}
};

const validPassword = (value) => {
	if (value.length < 6 || value.length > 40) {
		return (
			<div className='alert alert-danger' role='alert'>
				Le mot de passe doit posséder etre 6 et 40 caractères.
			</div>
		);
	}
};

const Register = (props) => {
	const form = useRef();
	const checkBtn = useRef();

	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState('');

	const onChangeUsername = (e) => {
		const username = e.target.value;
		setUsername(username);
	};

	const onChangeEmail = (e) => {
		const email = e.target.value;
		setEmail(email);
	};

	const onChangePassword = (e) => {
		const password = e.target.value;
		setPassword(password);
	};

	const handleRegister = (e) => {
		e.preventDefault();

		setMessage('');
		setSuccessful(false);

		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			AuthService.register(username, email, password).then(
				(response) => {
					setMessage(response.data.message);
					setSuccessful(true);
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
				},
			);
		}
	};

	return (
		<div className='col-md-12 jumbotron-top'>
			<div className='card card-container'>
				<img
					src='http://ssl.gstatic.com/accounts/ui/avatar_2x.png'
					alt='profile-img'
					className='profile-img-card'
				/>
				<Form onSubmit={handleRegister} ref={form}>
					{!successful && (
						<div>
							<div className='form-group'>
								<label htmlFor='username'>Nom de l'utilisateur</label>
								<Input
									type='text'
									className='form-control'
									name='username'
									value={username}
									onChange={onChangeUsername}
									validations={[required, validUsername]}
								/>
							</div>

							<div className='form-group'>
								<label htmlFor='email'>E-mail</label>
								<Input
									type='text'
									className='form-control'
									name='email'
									value={email}
									onChange={onChangeEmail}
									validations={[required, validEmail]}
								/>
							</div>

							<div className='form-group'>
								<label htmlFor='password'>Mot de passe</label>
								<Input
									type='password'
									className='form-control'
									name='password'
									value={password}
									onChange={onChangePassword}
									validations={[required, validPassword]}
								/>
							</div>

							<div className='form-group'>
								<button className='btn btn-primary btn-block'>
									S'enregistrer
								</button>
							</div>
						</div>
					)}

					{message && (
						<div className='form-group'>
							<div
								className={
									successful ? 'alert alert-success' : 'alert alert-danger'
								}
								role='alert'>
								{message}
							</div>
						</div>
					)}
					<CheckButton style={{ display: 'none' }} ref={checkBtn} />
				</Form>
			</div>
		</div>
	);
};

export default Register;
