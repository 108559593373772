import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import CheckButton from 'react-validation/build/button';

import AuthService from '../../../../../services/auth/auth.service';
import StructureDataService from '../../../../../services/StructureService';
import CategoryDataService from '../../../../../services/CategoryService';
import FileUpload from '../../../../Upload/FileUpload';

import { required } from '../../../../../utils/validation/fieldsCategorie';
import {
	vTitle,
	validEmail,
	vDescription,
	vTypeAgree,
	vLegalStatut,
	vDate,
	vSupervisor,
	vQuality,
	vContact,
	vAddress,
	vTel,
	vPort,
	// vFax,
	vWebsite,
	vProDomain,
	vCity,
	vClients,
	vNetworkMember,
} from '../../../../../utils/validation/fieldsStructure';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../../../App.css';

const AddStructure = () => {
	const initialStructureState = {
		category: '',
		id: null,
		title: '',
		description: '',
		type_of_agreement: '',
		legal_statut: '',
		creation_date: '',
		supervisor_identity: '',
		quality: '',
		contact_person: '',
		full_address: '',
		phone: '',
		mobile: '',
		fax: '',
		email: '',
		website: '',
		professionnal_domain: '',
		established_city: '',
		other_city: '',
		clients: '',
		network_membership: '',
		description_iae: '',
		career_opportunities: '',
		published: false,
	};

	const [structure, setStructure] = useState(initialStructureState);
	const [submitted, setSubmitted] = useState(false);
	const [categories, setCategories] = useState([]);

	const form = useRef();
	const checkBtn = useRef();
	const [message, setMessage] = useState('');
	const [selectedOption, setSelectedOption] = useState(0);
	const [showAdminBoard, setShowAdminBoard] = useState(false);

	const retrieveCategories = () => {
		CategoryDataService.getAll()
			.then((response) => {
				setCategories(response.data);
				console.log(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		const user = AuthService.getCurrentUser();
		if (user) {
			setShowAdminBoard(user.roles.includes('ROLE_ADMIN'));
		}
		retrieveCategories();
	}, []);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setStructure({ ...structure, [name]: value });
	};

	const handleChange = ({ target }) => {
		setSelectedOption(target.value);
	};

	const newStructure = () => {
		setStructure(initialStructureState);
		setSubmitted(false);
	};

	const saveStructure = (e) => {
		e.preventDefault();
		setMessage('');
		setSubmitted(false);
		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			const data = {
				category: selectedOption,
				title: structure.title,
				sampleLogoFile: structure.sample_logo_file,
				description: structure.description,
				type_of_agreement: structure.type_of_agreement,
				legal_statut: structure.legal_statut,
				creation_date: structure.creation_date,
				supervisor_identity: structure.supervisor_identity,
				quality: structure.quality,
				contact_person: structure.contact_person,
				full_address: structure.full_address,
				phone: structure.phone,
				mobile: structure.mobile,
				// fax: structure.fax,
				email: structure.email,
				website: structure.website,
				professionnal_domain: structure.professionnal_domain,
				established_city: structure.established_city,
				other_city: structure.other_city,
				clients: structure.clients,
				network_membership: structure.network_membership,
				description_iae: structure.description_iae,
				career_opportunities: structure.career_opportunities,
			};

			StructureDataService.create(data)
				.then(
					(response) => {
						setMessage(response.data.message);
						setSubmitted(true);
						setStructure({
							category: response.data.category,
							id: response.data.id,
							title: response.data.title,
							sampleLogoFile: response.sample_logo_file,
							description: response.data.description,
							type_of_agreement: response.data.type_of_agreement,
							legal_statut: response.data.legal_statut,
							creation_date: response.data.creation_date,
							supervisor_identity: response.data.supervisor_identity,
							quality: response.data.quality,
							contact_person: response.data.contact_person,
							full_address: response.data.full_address,
							phone: response.data.phone,
							mobile: response.data.mobile,
							// fax: response.data.fax,
							email: response.data.email,
							website: response.data.website,
							professionnal_domain: response.data.professionnal_domain,
							established_city: response.data.established_city,
							other_city: response.data.other_city,
							clients: response.data.clients,
							network_membership: response.data.network_membership,
							description_iae: response.data.description_iae,
							career_opportunities: response.data.career_opportunities,
							published: response.data.published,
						});
					},
					(error) => {
						const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();

						setMessage(resMessage);
						setSubmitted(false);
					},
				)
				.catch((e) => {
					console.log(e);
				});
		}
	};

	return (
		<div className='submit-form'>
			{showAdminBoard ? (
				showAdminBoard && (
					<Form onSubmit={saveStructure} ref={form}>
						{submitted ? (
							<div className='jumbotron jumbotron-top'>
								<h4>Votre publication a été envoyée avec succès !</h4>
								<button className='btn btn-success' onClick={newStructure}>
									Ajouter
								</button>
							</div>
						) : (
							<div>
								<div className='form-group'>
									<label className='add-tuto-top' htmlFor='category-select'>
										Choix d'une Catégorie:
									</label>

									<select
										value={selectedOption}
										id='category-select'
										onChange={handleChange}
										className='list-group'>
										{categories &&
											categories.map((category, index) => (
												<option key={index} value={category.id}>
													{category.title} - {category.subtitle}
												</option>
											))}
									</select>
								</div>
								<div className='form-group'>
									<label htmlFor='title'>
										Titre <span className='unbold'>(ex: AIAAC)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='title'
										value={structure.title}
										onChange={handleInputChange}
										name='title'
										validations={[required, vTitle]}
									/>
								</div>
								<div className='form-group'>
									<FileUpload />
								</div>
								<div className='form-group'>
									<label htmlFor='sample_logo_file'>
										Lien du Logo{' '}
										<span className='unbold'>
											(ex:
											https://storage.googleapis.com/samsara-siae-ll-hc-images/aiaac.jpg)
										</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='sample_logo_file'
										value={structure.sample_logo_file}
										onChange={handleInputChange}
										name='sample_logo_file'
									/>
								</div>
								{structure.sample_logo_file
									? console.log(
											'structure.sample_logo_file = ' +
												structure.sample_logo_file,
									  )
									: ''}
								<div className='form-group'>
									<label htmlFor='description'>
										Description{' '}
										<span className='unbold'>
											(ex: Association Intermédiaire Aide et Assistance
											Courriéroise.)
										</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='description'
										value={structure.description}
										onChange={handleInputChange}
										name='description'
										validations={[vDescription]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='type_of_agreement'>
										Type de conventionnement{' '}
										<span className='unbold'>
											(ex: Association Intermédiaire)
										</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='type_of_agreement'
										value={structure.type_of_agreement}
										onChange={handleInputChange}
										name='type_of_agreement'
										validations={[vTypeAgree]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='legal_statut'>
										Statut juridique{' '}
										<span className='unbold'>(ex: Association loi 1901)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='legal_statut'
										value={structure.legal_statut}
										onChange={handleInputChange}
										name='legal_statut'
										validations={[vLegalStatut]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='creation_date'>
										Date de création <span className='unbold'>(ex: 1989)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='creation_date'
										value={structure.creation_date}
										onChange={handleInputChange}
										name='creation_date'
										validations={[vDate]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='supervisor_identity'>
										Nom et prénom du responsable{' '}
										<span className='unbold'>(ex: Maria Thomas)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='supervisor_identity'
										value={structure.supervisor_identity}
										onChange={handleInputChange}
										name='supervisor_identity'
										validations={[vSupervisor]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='quality'>
										Qualité <span className='unbold'>(ex: Présidente)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='quality'
										value={structure.quality}
										onChange={handleInputChange}
										name='quality'
										validations={[vQuality]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='contact_person'>
										Personne à contacter{' '}
										<span className='unbold'>(ex: Alice Tadeau)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='contact_person'
										value={structure.contact_person}
										onChange={handleInputChange}
										name='contact_person'
										validations={[vContact]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='full_address'>
										Adresse postale complète{' '}
										<span className='unbold'>
											(ex: 95 rue des Ananas, 62710 COURRIÈRES)
										</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='full_address'
										value={structure.full_address}
										onChange={handleInputChange}
										name='full_address'
										validations={[vAddress]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='phone'>
										Téléphone{' '}
										<span className='unbold'>(ex: 03.65.99.79.22)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='phone'
										value={structure.phone}
										onChange={handleInputChange}
										name='phone'
										validations={[vTel]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='mobile'>
										Portable{' '}
										<span className='unbold'>(ex: 06.37.96.85.91)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='mobile'
										value={structure.mobile}
										onChange={handleInputChange}
										name='mobile'
										validations={[vPort]}
									/>
								</div>
								{/* <div className='form-group'>
									<label htmlFor='fax'>
										Fax <span className='unbold'>(ex: 03.91.84.23.18)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='fax'
										value={structure.fax}
										onChange={handleInputChange}
										name='fax'
										validations={[vFax]}
									/>
								</div> */}
								<div className='form-group'>
									<label htmlFor='email'>
										Email <span className='unbold'>(ex: test@yahoo.com)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='email'
										value={structure.email}
										onChange={handleInputChange}
										name='email'
										validations={[required, validEmail]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='website'>
										Site Internet{' '}
										<span className='unbold'>(ex: http://www.google.com)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='website'
										value={structure.website}
										onChange={handleInputChange}
										name='website'
										validations={[vWebsite]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='professionnal_domain'>
										Domaine professionnel{' '}
										<span className='unbold'>
											(ex: Manutention, Manœuvre, etc.)
										</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='professionnal_domain'
										value={structure.professionnal_domain}
										onChange={handleInputChange}
										name='professionnal_domain'
										validations={[vProDomain]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='established_city'>
										Commune d'implantation{' '}
										<span className='unbold'>(ex: Courrières)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='established_city'
										value={structure.established_city}
										onChange={handleInputChange}
										name='established_city'
										validations={[vCity]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='other_city'>
										Les autres sites <span className='unbold'>(ex: Aucun)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='other_city'
										value={structure.other_city}
										onChange={handleInputChange}
										name='other_city'
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='clients'>
										Clients{' '}
										<span className='unbold'>
											(ex: Entreprises, Collectivités, Associations,
											Particuliers.)
										</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='clients'
										value={structure.clients}
										onChange={handleInputChange}
										name='clients'
										validations={[vClients]}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='network_membership'>
										Appartenance à un groupe en réseau{' '}
										<span className='unbold'>(ex: Aucun)</span>
									</label>
									<Input
										type='text'
										className='form-control'
										id='network_membership'
										value={structure.network_membership}
										onChange={handleInputChange}
										name='network_membership'
										validations={[vNetworkMember]}
									/>
								</div>

								<div className='form-group'>
									<label htmlFor='description_iae'>
										Description de l'activité économique de la structure{' '}
										<span className='unbold'>
											(ex: Mises à disposition à titre onéreux, etc.)
										</span>
									</label>
									<Textarea
										className='form-control'
										id='description_iae'
										value={structure.description_iae}
										onChange={handleInputChange}
										name='description_iae'
										rows={5}
										cols={8}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='career_opportunities'>
										Débouchés professionnels possibles pour les salariés{' '}
										<span className='unbold'>
											(ex: Selon le projet professionnel du salarié.)
										</span>
									</label>
									<Textarea
										className='form-control'
										id='career_opportunities'
										value={structure.career_opportunities}
										onChange={handleInputChange}
										name='career_opportunities'
										rows={5}
										cols={8}
									/>
								</div>
								<button className='btn btn-success add-tuto-bottom'>
									Envoyer
								</button>
							</div>
						)}

						{message && (
							<div className='form-group'>
								<div
									className={
										submitted ? 'alert alert-success' : 'alert alert-danger'
									}
									role='alert'>
									{message}
								</div>
							</div>
						)}
						<CheckButton style={{ display: 'none' }} ref={checkBtn} />
					</Form>
				)
			) : (
				<div className='jumbotron jumbotron-top'>
					Vous ne disposez pas des droits pour atteindre cette page privée.
				</div>
			)}
		</div>
	);
};

export default AddStructure;
