import React, { useEffect, useState, useCallback } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import MarkerClusterer from '@googlemaps/markerclustererplus';

import CategoryDataService from '../../../services/CategoryService';

import './map.css';

const InitMap = () => {
	let map;
	let markers = [];

	const [currentIndex, setCurrentIndex] = useState(-1);
	const [categories, setCategories] = useState([]);

	/* eslint-disable no-unused-vars */
	const [currentStructure, setCurrentStructure] = useState(null);
	/* eslint-enable no-unused-vars */
	const [searchTitle, setSearchTitle] = useState('');

	const setActiveStructure = (structure, index) => {
		setCurrentStructure(structure);
		setCurrentIndex(index);
	};

	const handleClick = (e, structure, index) => {
		e.stopPropagation();
		setActiveStructure(structure, index);
	};

	/* eslint-disable no-unused-vars */
	const onChangeSearchTitle = (e) => {
		const searchTitle = e.target.value;
		setSearchTitle(searchTitle);
	};
	/* eslint-enable no-unused-vars */

	const getRequestParams = (searchTitle) => {
		let params = {};
		if (searchTitle) {
			params['title'] = searchTitle;
		}
		return params;
	};

	const retrieveCategories = useCallback(() => {
		const params = getRequestParams(searchTitle);

		CategoryDataService.getAll(params)
			.then((response) => {
				setCategories(response.data);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [searchTitle]);

	useEffect(() => {
		retrieveCategories();
	}, [retrieveCategories]);

	const additionalOptions = {}; // si besoin de charger des options avec le loader

	const loader = new Loader({
		apiKey: 'AIzaSyCdddZlo4zHKv0Zrxrs39Fvc7u66HfSSPg',
		version: 'weekly',
		...additionalOptions,
	});

	loader
		.load()
		.then(() => {
			let dataMap = {};
			dataMap.data = categories;
			// console.log(dataMap.data);
			/*
        [
          {  id: 5, title: "AI", subtitle: "Associations Intermédiaires", … },
          {  id: 15, title: "ACI", subtitle: "Ateliers et Chantiers d’Insertion", … },
          {  id: 25, title: "EI", subtitle: "Entreprises d’Insertion", … },
          {  id: 35, title: "ETTI", subtitle: "Entreprises de Travail Temporaire d’Insertion", … }
        ]
      */

			const infowindow = new window.google.maps.InfoWindow();
			const cityLens = new window.google.maps.LatLng(50.43222, 2.83333); // Ville de Lens au centre

			const mapOptions = {
				zoom: 11,
				center: cityLens,
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				// mapTypeId: 'd25ed7e54fde618e', // Pour test Vector
				disableDefaultUI: false,

				restriction: {
					// restriction de zone de scroll
					latLngBounds: {
						north: 50.685, // Latitude Nord en degrés, Limite vers Carvin
						south: 50.3233333, // Latitude Sud, Limite vers Vimy
						east: 3.215, // Longitude Sud, Limite vers Leforest
						west: 2.548611111111111, // Longitude Ouest, Limite vers Servins

						/*
          (Nord)
            Carvin 
            50° 29′ 38″ nord, 2° 57′ 32″ est
            degrés décimaux :
            latitude 50.4938889 
            longitude 2.958888888888889
          
          (Sud)
            Vimy 
            50° 22′ 24″ nord, 2° 48′ 41″ est
            degrés décimaux :
            latitude: 50.3733333
            longitude: 2.8113888888888887

          (Est)
            Leforest
            50° 26′ 17″ nord, 3° 03′ 54″ est
            degrés décimaux :
            latitude: 50.4380556
            longitude: 3.065

          (Ouest)
            Servins 
            50° 24′ 33″ nord, 2° 38′ 19″ est
            degrés décimaux :
            latitude: 50.4091667
            longitude: 2.638611111111111
            */
					},
				},
			};

			map = new window.google.maps.Map(
				document.getElementById('map'),
				mapOptions,
			);

			// désactive infoWindow sur clic map
			window.google.maps.event.addListener(map, 'click', function () {
				infowindow.close();
			});

			/* Début de Gestion de géolocalisation avec un bouton "Me localiser*" */

			const locationBtn = document.createElement('button');
			locationBtn.classList.add('button__localisation');
			locationBtn.textContent = 'Me localiser*';
			locationBtn.classList.add('custom-map-control-button');

			map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
				locationBtn,
			);

			locationBtn.addEventListener('click', () => {
				// Tentative de géolocalisation HTML5.
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(
						(position) => {
							const pos = {
								lat: position.coords.latitude,
								lng: position.coords.longitude,
							};
							infowindow.setPosition(pos);
							infowindow.setContent('Votre position*.');
							infowindow.open(map);
							map.setCenter(pos);
						},
						() => {
							handleLocationError(true, infowindow, map.getCenter());
						},
					);
				} else {
					// Le Navigateur ne gère pas le support de Géolocalisation
					handleLocationError(false, infowindow, map.getCenter());
				}
			});

			const handleLocationError = (browserHasGeolocation, infowindow, pos) => {
				infowindow.setPosition(pos);
				infowindow.setContent(
					browserHasGeolocation
						? "Erreur: Le service de géolocalisation n'est pas parvenue à trouver votre position."
						: "Erreur: Votre navigateur n'accepte pas le service de géolocalisation.",
				);
				infowindow.open(map);
			};

			// création de variables tableau pour simplifier les accès aux données
			// sous forme de listes de valeurs successives
			const titles = [];
			const addresses = [];
			const contentStrings = [];
			const elementsId = [];

			// fonction anonyme auto-appelante pour créer et injecter les données
			// dans les variables tableau précédemment créés
			(function () {
				let dataStructures = { structures: [] };
				dataMap.structures = dataStructures.structures;

				// rappel dataMap.data = categories;
				for (let i = 0; i < dataMap.data.length; i++) {
					for (let j = 0; j < dataMap.data[i].structures.length; j++) {
						let structure = {
							id: dataMap.data[i].structures[j].id,
							title: dataMap.data[i].structures[j].title,
							address: dataMap.data[i].structures[j].full_address,
						};

						let request = {
							query: structure.address,
							fields: ['name', 'geometry'],
						};

						structure['request'] = request;

						let titleCategory = dataMap.data[i].title;
						let urlCategory = titleCategory.toLowerCase();

						let contentHTML =
							'<div class="card">' +
							'<div class="card-body">' +
							`<h5 class="card-title">${structure.title}</h5>` +
							`<p class="card-text">${structure.request.query}</p>` +
							`<a href="/#/${urlCategory}/${structure.id}" class="btn btn-primary"` +
							`title="Vers la page de la structure ${structure.title}">La fiche</a >` +
							'</div>' +
							'</div>';

						structure['contentString'] = contentHTML;

						let infoWindowStructure = new window.google.maps.InfoWindow({
							content: contentHTML,
						});

						structure['infowindow'] = infoWindowStructure;
						dataStructures.structures.push(structure);
					}
				}

				// On ajoute chaque adresse, titre, contenu d'infowindow dans un tableau respectif
				dataMap.structures.map((structure) => {
					addresses.push(structure.address);
					titles.push(structure.title);
					contentStrings.push(structure.contentString);
					elementsId.push(structure.id);
				});

				// console.log(dataMap);
			})();

			// ! localisation GPS à ajouter manuellement pour chaque ajout de nouvelle structure
			const locations = [
				{ lat: 50.4602267, lng: 2.9532578 }, //  AIAAC - 5 rue des Acacias, 62710 COURRIÈRES
				{ lat: 50.426225448275545, lng: 2.815378186505131 }, // APSA Coup d'Main - 4 Rue de l'Eglise 62300 Lens
				{ lat: 50.41799243161312, lng: 2.776072868607153 }, // PARTENAIRES ACTION - 24 rue Emile Basly — BP 10162, 62800 LIÉVIN
				{ lat: 50.44396380000001, lng: 2.8278617 }, // RELAIS TRAVAIL - Pavillon ANET (4ème étage) — Grande Résidence BP 159, 62300 LENS
				{ lat: 50.427119, lng: 2.83044 }, // SAPI - 25 rue de la Gare — BP 286, 62300 LENS
				{ lat: 50.4195691, lng: 2.9451257 }, // SAPIH INSERTION - 5 rue Robert Aylé, 62110 HENIN BEAUMONT
				{ lat: 50.460884, lng: 2.7274051 }, // ACTIV’CITÉS - 1 Rue Aimé DUBOST, 62670 MAZINGARBE
				{ lat: 50.42446529999999, lng: 2.7917612 }, // 3 ID - 91 ter rue Jean Jaurès — BP 40191, 62800 LIEVIN
				{ lat: 50.422567746507376, lng: 2.934338522160079 }, // A.D.D.S. - ZAC de Montigny — Bld Jean Moulin — Résidence Aquitaine, 62640 MONTIGNY EN GOHELLE
				{ lat: 50.4455158, lng: 2.8044317 }, // LES ANGES GARDINS - Rue Vasco de Gama et Place de la République, 62750 LOOS EN GOHELLE
				{ lat: 50.42622201023466, lng: 2.8153459974425687 }, // A.P.S.A. INSERTION - 4 rue de l'Église — BP 115, 62302 LENS Cedex
				{ lat: 50.39354196633974, lng: 2.9260355 }, // D.I.E. - 77 Rte d'Arras, 62320 Drocourt
				{ lat: 50.3992121, lng: 2.8330176 }, // EL FOUAD - Les Fuschias, 6 rue Camélinat, 62210 AVION
				{ lat: 50.473977856809206, lng: 2.9743737512841677 }, // IMPULSION - Parc d'Activité du Château, 62220 CARVIN
				{ lat: 50.4480216, lng: 2.8399176 }, // INITIATIVE SOLIDAIRE - 281 rue de Londres, 62300 LENS
				{ lat: 50.4397574, lng: 2.6928775 }, // RECUP’TRI - Zal des Champs du Clerc, 62114 Aix-Noulette
				{ lat: 50.42051679999999, lng: 2.7766519 }, // VESTALI - 117 rue Jean-Baptiste Defernez, 62800 LIEVIN
				{ lat: 50.4358569, lng: 2.7829212 }, // PACTE 62 - 162 rue Jules GUESDE — BP 10144, 62803 LIEVIN Cedex
				{ lat: 50.4685417, lng: 2.720528 }, // ACTIV’CITES (EI) - 42 rue Alfred Lefebvre, 62670 MAZINGARBE
				{ lat: 50.42256114177775, lng: 2.93436722947576 }, // A.D.D.S. (EI) - ZAC de Montigny — Bld Jean Moulin — Résidence Aquitaine, 62640 MONTIGNY EN GOHELLE
				{ lat: 50.39354196633974, lng: 2.9260355 }, // D.I.E. (EI) - 77 Rte d'Arras, 62320 Drocourt
				{ lat: 50.42513560159267, lng: 2.78049996980517 }, // GOHELLE ENVIRONNEMENT - Rue de l'Abregain, 62800 Liévin
				{ lat: 50.4753056, lng: 2.9711505 }, // IMPULSION (EI) - Parc d'Activité du Château, 62220 CARVIN
				// { lat: 50.4718775, lng: 2.9985035 }, // LA LOCOMOTIVE - 60 rue Emile Zola, 62590 OIGNIES
				{ lat: 50.46462583506813, lng: 2.9200391127844383 }, // MAIN FORTE - Parc de la Motte du Bois Rue Gilbert Gheysens, 62440 Harnes
				{ lat: 50.464591384304924, lng: 2.9190775293388893 }, // SINEO - Parc d'Activité de la Motte au Bois, Rue Gilbert Gheysens, 62440 HARNES
				{ lat: 50.42742445230316, lng: 2.7843683900923804 }, // LES COUSALIS - 21 rue de la Convention, 62800 LIÉVIN
				{ lat: 50.4286417, lng: 2.8364123 }, // INTERINSER - 25 Avenue de Varsovie, 62300 Lens
				{ lat: 50.4917618, lng: 2.9767249 }, // JANUS SAS - 1005 Bât G — Rue Cyprien Quinet, 62220 CARVIN
				{ lat: 50.4315274, lng: 2.8294565 }, // PARTENAIRES INTERIM - 41 rue Victor Hugo, 62300 LENS
				{ lat: 50.43982747549961, lng: 2.921701208614513 }, // AFEJI - Zac des Chauffours, 62710 Courrières
				{ lat: 50.3864367675342, lng: 2.965399107263704 }, // Envie Nord - 3 Chemin de Bois BERNARD – 62110 Hénin Beaumont
				{ lat: 50.413302463564214, lng: 2.8159228965994334 }, // NINSERTION - 69 boulevard Henri MARTEL – 62210 AVION
			];

			function setBehaviorClickEventMarker(marker, index) {
				window.google.maps.event.addListener(marker, 'click', function () {
					infowindow.close();
					map.setCenter(marker.getPosition());
					map.setZoom(15);
					infowindow.setContent(contentStrings[index % contentStrings.length]);
					infowindow.open({
						anchor: marker,
						draggable: true,
						animation: window.google.maps.Animation.DROP,
						map,
						shouldFocus: true,
					});
				});
			}

			function addClickEventEachElem(arr, index, marker) {
				let elementId = arr[index % arr.length];
				if (elementId) {
					let liElement = document.getElementById(`${elementId}`);
					liElement.onclick = function (e) {
						e.cancelBubble = true;
						// e.returnValue = false obsolete
						if (e.stopPropagation) {
							e.stopPropagation();
							e.preventDefault();
						}
						window.google.maps.event.trigger(marker, 'click');
					};
				}
			}

			markers = locations.map((location, ii) => {
				const title = titles[ii % titles.length];

				if (title) {
					const marker = new window.google.maps.Marker({
						position: location,
						label: {
							text: `${title}`,
							className: 'label-marker',
						},
						title: title,
						optimized: true,
					});
					marker.addListener('click', function () {
						// 1er click marqueur = animation, second clic marqueur = no animation.
						if (marker.getAnimation() !== null) {
							marker.setAnimation(null);
						} else {
							marker.setAnimation(window.google.maps.Animation.BOUNCE);
						}
					});

					setBehaviorClickEventMarker(marker, ii);
					addClickEventEachElem(elementsId, ii, marker);

					return marker;
				}
			});

			// const imageClusterPath = 'https://samsara.live/siae/images/cluster/m';
			const imageClusterPath = 'https://www.siaellhc.fr/images/cluster/m';

			// Ajout de clusters grâce à la bibliothèque markerclustererplus
			// pour rassembler les marqueurs proches
			new MarkerClusterer(map, markers, {
				imagePath: imageClusterPath,
			});
		})
		.catch((error) => console.log(`Erreur avec MarkerClusterer : ${error}`));

	return (
		<div className='map-wrapper'>
			<div className='map-position row col-md-12'>
				<div className='col-md-12 title__map'>
					Cartographie des Structures d'Insertion de Lens-Liévin et
					d'Hénin-Carvin
				</div>
				<div id='map' className='col-md-8'></div>
				<div className='marker-list col-md-4'>
					<div className='card'>
						{categories &&
							categories.map((category, index) => (
								<div className='card-box'>
									<p>
										<button
											id={'id-cat-' + index}
											key={index}
											className='btn btn-primary'
											type='button'
											data-toggle='collapse'
											data-target={'.multi-collapse' + index}
											aria-expanded='false'
											aria-controls={'multiCollapseExample' + index}>
											{category.subtitle} ({category.title})
										</button>
									</p>
									<ul className='list-group list-group-flush'>
										<div
											key={index}
											className={'collapse multi-collapse' + index}
											id={'multiCollapseExample' + index}>
											{category.structures &&
												category.structures.map((structure, index) => (
													<li
														id={structure.id}
														className={
															'list-group-item-action list-group-item-primary list-group-item ' +
															(currentIndex === structure.id ? 'active' : '')
														}
														onClick={(e) =>
															handleClick(e, structure, structure.id)
														}
														key={index}>
														{structure.title}
													</li>
												))}
										</div>
									</ul>
								</div>
							))}
					</div>
				</div>
				<div className='message-localisation'>
					* Cette détection de localisation sur navigateur (grâce au HTML5)
					marche parfaitement sur mobile (gestion native du GPS), mais est moins
					performante sur ordinateur (via l'adresse IP). Le résultat de cette
					géolocalisation sur ordinateur n'est donc parfois pas précis, et cela
					dépend également des paramétrages de votre session de navigateur.
				</div>
			</div>
		</div>
	);
};

export default InitMap;
